export const markets = [
    {
        value: "Account Opening",
        label: "Account Opening"
    },
    {
        value: "Bill Pay Portafolio",
        label: "Bill Pay Portafolio"
    },
    {
        value: "Bin Sponsor",
        label: "Bin Sponsor"
    },
    {
        value: "Cash In / Out",
        label: "Cash In / Out"
    },
    {
        value: "Merchant Acquiror",
        label: "Merchant Acquiror"
    },
    {
        value: "SPEI In / Out",
        label: "SPEI In / Out"
    },
];

export const countries = [
    {
        value: "Austria",
        label: "Austria",
    },
    {
        value: "Andorra",
        label: "Andorra",
    },
    {
        value: "Afghanistan",
        label: "Afghanistan",
    },
    {
        value: "Anguilla",
        label: "Anguilla",
    },
    {
        value: "Albania",
        label: "Albania",
    },
    {
        value: "Armenia",
        label: "Armenia",
    },
    {
        value: "Angola",
        label: "Angola",
    },
    {
        value: "Antarctica",
        label: "Antarctica",
    },
    {
        value: "Argentina",
        label: "Argentina",
    },
    {
        value: "Australia",
        label: "Australia",
    },
    {
        value: "Aruba",
        label: "Aruba",
    },
    {
        value: "Aland Islands",
        label: "Aland Islands",
    },
    {
        value: "Azerbaijan",
        label: "Azerbaijan",
    },
    {
        value: "Algeria",
        label: "Algeria",
    },
    {
        value: "Belgium",
        label: "Belgium",
    },
    {
        value: "Bosnia and Herzegovina",
        label: " Bosnia and Herzegovina",
    },
    {
        value: "Bangladesh",
        label: "Bangladesh",
    },
    {
        value: "Barbados",
        label: "Barbados",
    },
    {
        value: "Burkina Faso",
        label: "Burkina Faso",
    },
    {
        value: "Bulgaria",
        label: "Bulgaria",
    },
    {
        value: "Bahrain",
        label: "Bahrain",
    },
    {
        value: "Burundi",
        label: "Burundi",
    },
    {
        value: "Benin",
        label: "Benin",
    },
    {
        value: "Bermuda",
        label: "Bermuda",
    },
    {
        value: "Brunei Darussalam",
        label: "Brunei Darussalam",
    },
    {
        value: "Bolivia, Plurinational State of",
        label: "Bolivia, Plurinational State of",
    },
    {
        value: "Bonaire, Sint Eustatius and Saba",
        label: "Bonaire, Sint Eustatius and Saba",
    },
    {
        value: "Brazil",
        label: "Brazil",
    },
    {
        value: "Bahamas",
        label: "Bahamas",
    },
    {
        value: "Bhutan",
        label: "Bhutan",
    },
    {
        value: "Bouvet Island",
        label: "Bouvet Island",
    },
    {
        value: "Botswana",
        label: "Botswana",
    },
    {
        value: "Belarus",
        label: "Belarus",
    },
    {
        value: "Belize",
        label: "Belize",
    },
    {
        value: "Brunei",
        label: "Brunei",
    },
    {
        value: "Bolivia",
        label: "Bolivia",
    },
    {
        value: "Bonaire Saint Eustatius and Saba",
        label: "Bonaire Saint Eustatius and Saba",
    },
    {
        value: "British Indian Ocean Territory",
        label: "British Indian Ocean Territory",
    },
    {
        value: "Canada",
        label: "Canada",
    },
    {
        value: "Cocos (Keeling) Islands",
        label: "Cocos (Keeling) Islands ",
    },
    {
        value: "Central African Republic",
        label: "Central African Republic",
    },
    {
        value: "Cote d’Ivoire",
        label: "Cote d’Ivoire",
    },
    {
        value: "Cook Islands",
        label: "Cook Islands",
    },
    {
        value: "Chile",
        label: "Chile",
    },
    {
        value: "Cameroon",
        label: "Cameroon",
    },
    {
        value: "China",
        label: "China",
    },
    {
        value: "Colombia",
        label: "Colombia",
    },
    {
        value: "Cocos Islands",
        label: "Cocos Islands",
    },
    {
        value: "Costa Rica",
        label: "Costa Rica",
    },
    {
        value: "Cuba",
        label: "Cuba",
    },
    {
        value: "Cape Verde",
        label: "Cape Verde",
    },
    {
        value: "Curaçao",
        label: "Curaçao",
    },
    {
        value: "Christmas Island",
        label: "Christmas Island",
    },
    {
        value: "Cyprus",
        label: "Cyprus",
    },
    {
        value: "Czech Republic",
        label: "Czech Republic",
    },
    {
        value: "Curacao",
        label: "Curacao",
    },
    {
        value: "Croatia",
        label: "Croatia",
    },
    {
        value: "Cambodia",
        label: "Cambodia",
    },
    {
        value: "Comoros",
        label: "Comoros",
    },
    {
        value: "Cayman Islands",
        label: "Cayman Islands",
    },
    {
        value: "Chad",
        label: "Chad",
    },
    {
        value: "Denmark",
        label: "Denmark",
    },
    {
        value: "Djibouti",
        label: "Djibouti",
    },
    {
        value: "Dominica",
        label: "Dominica",
    },
    {
        value: "Dominican Republic",
        label: "Dominican Republic",
    },
    {
        value: "Ecuador",
        label: "Ecuador",
    },
    {
        value: "Estonia",
        label: "Estonia",
    },
    {
        value: "Egypt",
        label: "Egypt",
    },
    {
        value: "Eritrea",
        label: "Eritrea",
    },
    {
        value: "Ethiopia",
        label: "Ethiopia",
    },
    {
        value: "Equatorial Guinea",
        label: "Equatorial Guinea",
    },
    {
        value: "El Salvador",
        label: "El Salvador",
    },
    {
        value: "East Timor",
        label: "East Timor",
    },
    {
        value: "Finland",
        label: "Finland",
    },
    {
        value: "France",
        label: "France",
    },
    {
        value: "Fiji",
        label: "Fiji",
    },
    {
        value: "Falkland Islands (Malvinas)",
        label: "Falkland Islands (Malvinas)",
    },
    {
        value: "Faroe Islands",
        label: "Faroe Islands",
    },
    {
        value: "French Guiana",
        label: "French Guiana",
    },
    {
        value: "Falkland Islands",
        label: "Falkland Islands",
    },
    {
        value: "French Polynesia",
        label: "French Polynesia",
    },
    {
        value: "French Southern Territories",
        label: "French Southern Territories",
    },
    {
        value: "Germany",
        label: "Germany",
    },
    {
        value: "Guam",
        label: "Guam",
    },
    {
        value: "Gabon",
        label: "Gabon",
    },
    {
        value: "Grenada",
        label: "Grenada",
    },
    {
        value: "Georgia",
        label: "Georgia",
    },
    {
        value: "Guernsey",
        label: "Guernsey",
    },
    {
        value: "Ghana",
        label: "Ghana",
    },
    {
        value: "Gibraltar",
        label: "Gibraltar",
    },
    {
        value: "Greenland",
        label: "Greenland",
    },
    {
        value: "Gambia",
        label: "Gambia",
    },
    {
        value: "Guinea",
        label: "Guinea",
    },
    {
        value: "Guadeloupe",
        label: "Guadeloupe",
    },
    {
        value: "Greece",
        label: "Greece",
    },
    {
        value: "Guatemala",
        label: "Guatemala",
    },
    {
        value: "Guinea-Bissau",
        label: "Guinea-Bissau",
    },
    {
        value: "Guyana",
        label: "Guyana",
    },
    {
        value: "Ireland",
        label: "Ireland",
    },
    {
        value: "Italy",
        label: "Italy",
    },
    {
        value: "Latvia",
        label: "Latvia",
    },
    {
        value: "Lithuania",
        label: "Lithuania",
    },
    {
        value: "Netherlands",
        label: "Netherlands",
    },
    {
        value: "Norway",
        label: "Norway",
    },
    {
        value: "Poland",
        label: "Poland",
    },
    {
        value: "Portugal",
        label: "Portugal",
    },
    {
        value: "Spain",
        label: "Spain",
    },
    {
        value: "Sweden",
        label: "Sweden",
    },
    {
        value: "United Kingdom",
        label: "United Kingdom",
    },
    {
        value: "United Arab Emirates",
        label: "United Arab Emirates",
    },
    {
        value: "Switzerland",
        label: "Switzerland",
    },
    {
        value: "Marshall Islands",
        label: "Marshall Islands",
    },
    {
        value: "Micronesia",
        label: "Micronesia",
    },
    {
        value: "Northern Mariana Islands",
        label: " Northern Mariana Islands",
    },
    {
        value: "Palau",
        label: "Palau",
    },
    {
        value: "Saint Barthélemy",
        label: "Saint Barthélemy",
    },
    {
        value: "Puerto Rico",
        label: "Puerto Rico",
    },
    {
        value: "U.S. Virgin Islands",
        label: "U.S. Virgin Islands",
    },
    {
        value: "Hong Kong",
        label: "Hong Kong",
    },
    {
        value: "United States Minor Outlying Islands",
        label: " United States Minor Outlying Islands ",
    },
    {
        value: "Saint Barthelemy",
        label: "Saint Barthelemy",
    },
    {
        value: "Republic of the Congo",
        label: " Republic of the Congo ",
    },
    {
        value: "Ivory Coast",
        label: "Ivory Coast",
    },
    {
        value: "Western Sahara",
        label: "Western Sahara",
    },
    {
        value: "South Georgia and the South Sandwich Islands",
        label: "South Georgia and the South Sandwich Islands",
    },
    {
        value: "Heard Island and McDonald Islands",
        label: "Heard Island and McDonald Islands",
    },
    {
        value: "Honduras",
        label: "Honduras",
    },
    {
        value: "Haiti",
        label: "Haiti",
    },
    {
        value: "Hungary",
        label: "Hungary",
    },
    {
        value: "Indonesia",
        label: "Indonesia",
    },
    {
        value: "Israel",
        label: "Israel",
    },
    {
        value: "Isle of Man",
        label: "Isle of Man",
    },
    {
        value: "India",
        label: "India",
    },
    {
        value: "Iraq",
        label: "Iraq",
    },
    {
        value: "Iran, Islamic Republic of",
        label: "Iran, Islamic Republic of",
    },
    {
        value: "Iceland",
        label: "Iceland",
    },
    {
        value: "Jersey",
        label: "Jersey",
    },
    {
        value: "Jamaica",
        label: "Jamaica",
    },
    {
        value: "Jordan",
        label: "Jordan",
    },
    {
        value: "Japan",
        label: "Japan",
    },
    {
        value: "Kenya",
        label: "Kenya",
    },
    {
        value: "Kyrgyzstan",
        label: "Kyrgyzstan",
    },
    {
        value: "Kiribati",
        label: "Kiribati",
    },
    {
        value: "Saint Kitts and Nevis",
        label: "Saint Kitts and Nevis ",
    },
    {
        value: "Korea, Democratic People’s Republic of",
        label: "Korea, Democratic People’s Republic of",
    },
    {
        value: "Korea, Republic of",
        label: "Korea, Republic of",
    },
    {
        value: "Kuwait",
        label: "Kuwait",
    },
    {
        value: "Kazakhstan",
        label: "Kazakhstan",
    },
    {
        value: "Lao People’s Democratic Republic",
        label: "Lao People’s Democratic Republic",
    },
    {
        value: "Lebanon",
        label: "Lebanon",
    },
    {
        value: "Saint Lucia",
        label: "Saint Lucia",
    },
    {
        value: "Liechtenstein",
        label: "Liechtenstein",
    },
    {
        value: "Sri Lanka",
        label: "Sri Lanka",
    },
    {
        value: "Liberia",
        label: "Liberia",
    },
    {
        value: "North Korea",
        label: "North Korea",
    },
    {
        value: "Lesotho",
        label: "Lesotho",
    },
    {
        value: "Luxembourg",
        label: "Luxembourg",
    },
    {
        value: "Libyan Arab Jamahiriya",
        label: "Libyan Arab Jamahiriya",
    },
    {
        value: "Morocco",
        label: "Morocco",
    },
    {
        value: "Monaco",
        label: "Monaco",
    },
    {
        value: "Moldova, Republic of",
        label: "Moldova, Republic of",
    },
    {
        value: "Montenegro",
        label: "Montenegro",
    },
    {
        value: "Saint Martin (French part)",
        label: "Saint Martin (French part) ",
    },
    {
        value: "Madagascar",
        label: "Madagascar",
    },
    {
        value: "Macedonia, the former Yugoslav Republic of",
        label: "Macedonia, the former Yugoslav Republic of",
    },
    {
        value: "Mali",
        label: "Mali",
    },
    {
        value: "Myanmar",
        label: "Myanmar",
    },
    {
        value: "Mongolia",
        label: "Mongolia",
    },
    {
        value: "Macao",
        label: "Macao",
    },
    {
        value: "Martinique",
        label: "Martinique",
    },
    {
        value: "Mauritania",
        label: "Mauritania",
    },
    {
        value: "Moldova",
        label: "Moldova",
    },
    {
        value: "Montserrat",
        label: "Montserrat",
    },
    {
        value: "Malta",
        label: "Malta",
    },
    {
        value: "Saint Martin",
        label: "Saint Martin",
    },
    {
        value: "Mauritius",
        label: "Mauritius",
    },
    {
        value: "Maldives",
        label: "Maldives",
    },
    {
        value: "Malawi",
        label: "Malawi",
    },
    {
        value: "Malaysia",
        label: "Malaysia",
    },
    {
        value: "Mozambique",
        label: "Mozambique",
    },
    {
        value: "Namibia",
        label: "Namibia",
    },
    {
        value: "New Caledonia",
        label: "New Caledonia",
    },
    {
        value: "Niger",
        label: "Niger",
    },
    {
        value: "Norfolk Island",
        label: "Norfolk Island",
    },
    {
        value: "Nigeria",
        label: "Nigeria",
    },
    {
        value: "Nicaragua",
        label: "Nicaragua",
    },
    {
        value: "Nepal",
        label: "Nepal",
    },
    {
        value: "Nauru",
        label: "Nauru",
    },
    {
        value: "Niue",
        label: "Niue",
    },
    {
        value: "New Zealand",
        label: "New Zealand",
    },
    {
        value: "Oman",
        label: "Oman",
    },
    {
        value: "Panama",
        label: "Panama",
    },
    {
        value: "Peru",
        label: "Peru",
    },
    {
        value: "Papua New Guinea",
        label: "Papua New Guinea",
    },
    {
        value: "Philippines",
        label: "Philippines",
    },
    {
        value: "Pakistan",
        label: "Pakistan",
    },
    {
        value: "Saint Pierre and Miquelon",
        label: "Saint Pierre and Miquelon",
    },
    {
        value: "Pitcairn",
        label: "Pitcairn",
    },
    {
        value: "Palestine",
        label: "Palestine",
    },
    {
        value: "Paraguay",
        label: "Paraguay",
    },
    {
        value: "Qatar",
        label: "Qatar",
    },
    {
        value: "Reunion",
        label: "Reunion",
    },
    {
        value: "Romania",
        label: "Romania",
    },
    {
        value: "Serbia",
        label: "Serbia",
    },
    {
        value: "Russian Federation",
        label: "Russian Federation",
    },
    {
        value: "Rwanda",
        label: "Rwanda",
    },
    {
        value: "Saudi Arabia",
        label: "Saudi Arabia",
    },
    {
        value: "Solomon Islands",
        label: "Solomon Islands",
    },
    {
        value: "Seychelles",
        label: "Seychelles",
    },
    {
        value: "Sudan",
        label: "Sudan",
    },
    {
        value: "Singapore",
        label: "Singapore",
    },
    {
        value: "Saint Helena, Ascension and Tristan da Cunha",
        label: " Saint Helena, Ascension and Tristan da Cunha",
    },
    {
        value: "Slovenia",
        label: "Slovenia",
    },
    {
        value: "Svalbard and Jan Mayen",
        label: "Svalbard and Jan Mayen",
    },
    {
        value: "Slovakia",
        label: "Slovakia",
    },
    {
        value: "Sierra Leone",
        label: "Sierra Leone",
    },
    {
        value: "San Marino",
        label: "San Marino",
    },
    {
        value: "Senegal",
        label: "Senegal",
    },
    {
        value: "Somalia",
        label: "Somalia",
    },
    {
        value: "Suriname",
        label: "Suriname",
    },
    {
        value: "South Sudan",
        label: "South Sudan",
    },
    {
        value: "Saint Helena",
        label: "Saint Helena",
    },
    {
        value: "Sao Tome and Principe",
        label: "Sao Tome and Principe",
    },
    {
        value: "Sint Maarten (Dutch part)",
        label: "Sint Maarten (Dutch part)",
    },
    {
        value: "Syrian Arab Republic",
        label: "Syrian Arab Republic",
    },
    {
        value: "Swaziland",
        label: "Swaziland",
    },
    {
        value: "Turks and Caicos Islands",
        label: "Turks and Caicos Islands",
    },
    {
        value: "Togo",
        label: "Togo",
    },
    {
        value: "Thailand",
        label: "Thailand",
    },
    {
        value: "Tajikistan",
        label: "Tajikistan",
    },
    {
        value: "Tokelau",
        label: "Tokelau",
    },
    {
        value: "Sint Maarten",
        label: "Sint Maarten",
    },
    {
        value: "Timor-Leste",
        label: "Timor-Leste",
    },
    {
        value: "Syria",
        label: "Syria",
    },
    {
        value: "Turkmenistan",
        label: "Turkmenistan",
    },
    {
        value: "Tunisia",
        label: "Tunisia",
    },
    {
        value: "Tonga",
        label: "Tonga",
    },
    {
        value: "Turkey",
        label: "Turkey",
    },
    {
        value: "Trinidad and Tobago",
        label: "Trinidad and Tobago",
    },
    {
        value: "Tuvalu",
        label: "Tuvalu",
    },
    {
        value: "Taiwan",
        label: "Taiwan",
    },
    {
        value: "Ukraine",
        label: "Ukraine",
    },
    {
        value: "Uganda",
        label: "Uganda",
    },
    {
        value: "United States",
        label: "United States",
    },
    {
        value: "Uruguay",
        label: "Uruguay",
    },
    {
        value: "Uzbekistan",
        label: "Uzbekistan",
    },
    {
        value: "Holy See (Vatican City State)",
        label: "Holy See (Vatican City State)",
    },
    {
        value: "Saint Vincent and the Grenadines",
        label: "Saint Vincent and the Grenadines",
    },
    {
        value: "Venezuela, Bolivarian Republic of",
        label: "Venezuela, Bolivarian Republic of",
    },
    {
        value: "Virgin Islands, British",
        label: "Virgin Islands, British",
    },
    {
        value: "Tanzania",
        label: "Tanzania",
    },
    {
        value: "Vietnam",
        label: "Vietnam",
    },
    {
        value: "Vanuatu",
        label: "Vanuatu",
    },
    {
        value: "Wallis and Futuna",
        label: "Wallis and Futuna",
    },
    {
        value: "Samoa",
        label: "Samoa",
    },
    {
        value: "Yemen",
        label: "Yemen",
    },
    {
        value: "Mayotte",
        label: "Mayotte",
    },
    {
        value: "South Africa",
        label: "South Africa",
    },
    {
        value: "Vatican",
        label: "Vatican",
    },
    {
        value: "Zambia",
        label: "Zambia",
    },
    {
        value: "Venezuela",
        label: "Venezuela",
    },
    {
        value: "Zimbabwe",
        label: "Zimbabwe",
    },
];

export const industries = [
    {
        value: "Asset & Wealth Management",
        label: "Asset & Wealth Management"
    },
    {
        value: "Banking",
        label: "Banking"
    },
    {
        value: "Buy Now Pay Later",
        label: "Buy Now Pay Later"
    },
    {
        value: "Comparison Sites",
        label: "Comparison Sites"
    },
    {
        value: "Consultancy",
        label: "Consultancy"
    },
    {
        value: "Crypto",
        label: "Crypto"
    },
    {
        value: "Digital Banks",
        label: "Digital Banks"
    },
    {
        value: "Gaming",
        label: "Gaming"
    },
    {
        value: "Insurance",
        label: "Insurance"
    },
    {
        value: "IT & IT Systems",
        label: "IT & IT Systems"
    },
    {
        value: "Lending",
        label: "Lending"
    },
    {
        value: "Money management",
        label: "Money management"
    },
    {
        value: "Payments",
        label: "Payments"
    },
    {
        value: "Proptech",
        label: "Proptech"
    },
    {
        value: "Real Estate",
        label: "Real Estate"
    },
    {
        value: "RegTech",
        label: "RegTech"
    },
    {
        value: "Retail and Consumer Products",
        label: "Retail and Consumer Products"
    },
    {
        value: "Utilities & Telcos",
        label: "Utilities & Telcos"
    },
    {
        value: "Other",
        label: "Otro"
    }
]

export const sizeCompany = [
    {
        value: "1-10 employees",
        label: "1-10 employees"
    },
    {
        value: "11-50 employees",
        label: "11-50 employees"
    },
    {
        value: "51-200 employees",
        label: "51-200 employees"
    },
    {
        value: "201-500 employees",
        label: "201-500 employees"
    },
    {
        value: "501-1000 employees",
        label: "501-1000 employees"
    },
    {
        value: "1001+ employees",
        label: "1001+ employees"
    },
    {
        value: "Self-employed",
        label: "Self-employed"
    }
]

export const FAQ = [
    {
        title: 'Generales',
        content: [
            {
                key: 0,
                subtitle: '¿Qué es un CVV dinámico?',
                content: <>El CVV (Card Verification Value) es el código de seguridad que normalmente se encuentra atrás de tu tarjeta física. En este caso, el CVV dinámico se encuentra en tu aplicación, lo generas cuando deseas realizar una compra en línea y este se despliega por 5 minutos. El CVV se genera aleatoriamente para otorgarte mayor seguridad para tus compras en línea.</>
            }
        ]
    },
    {
        title: 'Banco PagaTodo',
        content: [
            {
                key: 0,
                subtitle: '¿Qué tipos de cuentas bancarias puedo crear?',
                content: <>Si eres persona física: puedes abrir una Cuenta Útil, Cuenta Ya Ganaste, Cuenta Básica o Cuenta Básica de Nómina. <br/><br/> Si eres persona moral: puedes abrir una Cuenta Corporativa. <br/><br/> Si eres empleado o funcionario del Grupo PagaTodo: puedes abrir una Cuenta Fundadores.</>
            },
            {
                key: 1,
                subtitle: '¿Abrir una cuenta bancaria tiene costo?',
                content: 'Ninguna de nuestras cuentas tienen costo de apertura.'
            },
            {
                key: 2,
                subtitle: '¿Cobran comisiones?',
                content: 'Consulta las comisiones disponibles aquí.'
            }
        ]
    },
    {
        title: 'App',
        content: [
            {
                key: 0,
                subtitle: '¿Cómo descargo la app?',
                content: 'Puedes descargar la app para dispositivos Android en GooglePlay y para dispositivos con iOS en App Store.'
            },
            {
                key: 1,
                subtitle: 'Perdí mi tarjeta, ¿cómo la puedo bloquear?',
                content: 'Entra a la app de Banco PagaTodo, selecciona la opción “Bloquear Tarjeta”, ingresa tu contraseña y presiona “Continuar”. También puedes contactarnos al 800 500 1000 y levantar tu ticket para realizar esta operación.'
            },
            {
                key: 2,
                subtitle: '¿Qué tipo de servicios puedo pagar?',
                content: 'Puedes pagar servicios básicos como luz, agua, internet, telepeaje y muchos más.'
            },
            {
                key: 3,
                subtitle: '¿Cómo cobrar con CoDi®?',
                content: <>Para empezar a cobrar con CoDi® necesitas: <br/> • Una cuenta. <br/> • Una aplicación para generar mensajes de cobro. Esta aplicación puede proporcionártela la institución bancaria, o bien, puedes utilizar la aplicación CoDi Banxico para este propósito.<br/>El funcionamiento para cobrar con CoDi® es el siguiente:<br/>• El cobrador que va recibir el dinero por el monto por el bien o servicio genera un mensaje a través de su dispositivo móvil.<br/>• El pagador recibe y acepta el mensaje de cobro. Lo que se traduce en una transferencia electrónica, interbancaria o mismo banco.<br/>Una vez que se completa la transferencia, CoDi® notifica al instante, tanto al cobrador como al pagador, la confirmación del pago.</>
            },
            {
                key: 4,
                subtitle: '¿Cómo pagar con CoDi®?',
                content: <>El funcionamiento para pagar con CoDi® es el siguiente:<br/><br/>• Previamente, la persona que va a recibir el dinero, es decir, el cobrador es quien inicia la operación generando un mensaje de cobro.<br/>• El pagador recibe y acepta el mensaje de cobro generado. Lo que se traduce en una transferencia electrónica, interbancaria o mismo banco.<br/>Una vez que se completa la transferencia, CoDi® notifica al instante, tanto al cobrador como al pagador, la confirmación del pago.<br/>Para más información consulta: https://www.banxico.org.mx/sistemas-de-pago/codi-cobro-digital-banco-me.html</>
            },
            {
                key: 5,
                subtitle: '¿Cómo genero un CVV dinámico?',
                content: 'A través de tu aplicación, entra a la sección de “Compras en Línea” y podrás ver el CVV dinámico.'
            }
        ]
    }
]