import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HashLink } from "react-router-hash-link";
import { options, organizations, socialMedia } from "temp/footer";
//images
import logo from "images/logo.png";
import phone from "images/phone.png";
import email from "images/email.png";
import ipab from "images/ipab.png";
import une from "images/une.png";

const Footer = () => {
  return (
    <footer className="footer-wrapper">
      <Container>
        <Row>
          <Col className="text-center" xs={12} sm={12} md={12} lg={12}>
            <h2>Te escuchamos</h2>
            <ul className="footer-wrapper__flexs">
              <li className="footer-wrapper__flexs aling-item-center">
                <img src={phone} alt="icon-footer" />
                <p className="footer__menu">
                  {" "}
                  <a href="tel:8005001000" className="footer__menu">
                    800 500 1000
                  </a>
                </p>
              </li>
              <li className="footer-wrapper__flexs aling-item-center">
                <img src={email} alt="icon-footer" />
                <p style={{ color: "#000000!important" }}>
                  {" "}
                  <a
                    href="mailto:contacto@pagatodo.com"
                    className="footer__menu"
                  >
                    contacto@pagatodo.com
                  </a>{" "}
                </p>
              </li>
            </ul>
          </Col>
          <Col className="text-center" xs={12} sm={12} md={12} lg={12}>
            <h2 className="mt-3">Síguenos</h2>
            <ul className="footer-wrapper__flex is-flex aling-item-center">
              {socialMedia.map((item, index) => (
                <li key={index}>
                  <a target="_blank" rel="noreferrer" href={item.url}>
                    <img
                      style={{ height: 24 }}
                      src={item.icon}
                      onMouseEnter={(e) => (e.currentTarget.src = item.iconHov)}
                      onMouseLeave={(e) => (e.currentTarget.src = item.icon)}
                      alt={`social-media-${index + 1}`}
                    />
                  </a>
                </li>
              ))}
            </ul>
          </Col>
        </Row>
        <Row className="g-0 footer-wrapper__menus mt-4">
          {options.map((item, index) => (
            <Col
              xs={5}
              sm={6}
              md={6}
              lg={4}
              className="footer-wrapper__menu"
              key={index}
            >
              <h2>{item.title}</h2>
              <ul>
                {item.options.map((i, index) => (
                  <li key={index}>
                    <HashLink to={i.path} className="footer__menu">
                      {i.description}
                    </HashLink>
                  </li>
                ))}
              </ul>
            </Col>
          ))}
        </Row>
        <Row className="g-0 footer-wrapper__item">
          <Col xs={12} sm={12} md={12} lg={12}>
            Da clic aquí para conecer más sobre:
            <a
              className="text-web"
              href="/pdfs/IPAB.pdf"
              target="_blank"
              style={{
                color: "#5B6575",
                textAlign: "center",
                marginTop: "-15px",
              }}
            >
              <u>Productos garantizados por el IPAB por 400 mil UDIs </u>
              <span>
                <img className="ipab-img" src={ipab} alt="icon-footer" />
              </span>
            </a>
            <a
              className="text-tablet"
              href="/pdfs/IPAB.pdf"
              target="_blank"
              style={{
                color: "#5B6575",
                textAlign: "center",
                marginTop: "-11px",
              }}
            >
              <u>Productos garantizados por el IPAB por 400 mil UDIs </u>
              <span>
                <img className="ipab-img" src={ipab} alt="icon-footer" />
              </span>
            </a>
            <a
              className="text-mobile"
              href="/pdfs/IPAB.pdf"
              target="_blank"
              style={{
                color: "#5B6575",
                textAlign: "center",
                marginTop: "-7px",
              }}
            >
              <u>Productos garantizados por el IPAB por 400 mil UDIs </u>
              <span>
                <img className="ipab-img" src={ipab} alt="icon-footer" />
              </span>
            </a>
          </Col>
          <Col className="text-center ipab-col" xs={12} sm={12} md={12} lg={12}>
            <img src={ipab} alt="icon-footer" />
          </Col>
        </Row>
      </Container>
      <div className="footer-wrapper__info">
        <Container>
          <Row>
            <Col
              className="footer-wrapper__info-1"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <img src={logo} alt="logo" />
              <p className="m-0">Blvd. Manuel Ávila Camacho 66, Piso 2,</p>
              <p className="m-0">
                Centro Comercial Lomas Plaza, Colonia Lomas de Chapultepec,
              </p>
              <p className="m-0">
                C.P. 11000, Delegación Miguel Hidalgo, Ciudad de México.
              </p>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col
              className="footer-wrapper__info-1"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <HashLink
                to="/informacionfinanciera#finance"
                className="footer__menu"
              >
                Información financiera
              </HashLink>
              &nbsp;|&nbsp;
              <HashLink to="/avisolegal#legal" className="footer__menu">
                Aviso legal
              </HashLink>
              &nbsp;|&nbsp;
              <a
                href="/pdfs/Aviso_De_Privacidad_BPT.pdf"
                target="_blank"
                rel="noreferrer"
                className="footer__menu"
              >
                Aviso de privacidad
              </a>
              &nbsp;|&nbsp;
              <HashLink
                to="/avisodeseguridad#security"
                className="footer__menu"
              >
                Aviso de seguridad
              </HashLink>
              &nbsp;|&nbsp;
              <br />
              <HashLink to="/buro#buro" className="footer__menu">
                Buró de entidades financieras
              </HashLink>
              &nbsp;|&nbsp;
              <HashLink to="/productos#products" className="footer__menu">
                Productos
              </HashLink>
              &nbsp;|&nbsp;
              <a
                href="/pdfs/Aviso_BPT_DC.pdf"
                target="_blank"
                rel="noreferrer"
                className="footer__menu"
              >
                Aviso de datos Despacho de Cobranza
              </a>
            </Col>
            <Col
              className="footer-wrapper__info-logos"
              xs={12}
              sm={12}
              md={6}
              lg={6}
            >
              <ul className="footer-wrapper__logos">
                {/* <li><HashLink to='/une#une'><img src={une} alt={`org-img-${une}`}/></HashLink></li> */}
                <li>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={"/pdfs/une/UNE(JUL-2024).pdf"}
                  >
                    <img src={une} alt={`org-img-${une}`} />
                  </a>
                </li>
                {organizations.map((item, index) => (
                  <li key={index}>
                    {" "}
                    <a target="_blank" rel="noreferrer" href={item.url}>
                      {" "}
                      <img src={item.icon} alt={`org-img-${index + 1}`} />
                    </a>
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <hr style={{ margin: 0, backgroundColor: "#FFFFFF" }} />
      <div className="footer-wrapper__info">
        <Container>
          <Row>
            <Col
              className="footer-wrapper__info-1"
              xs={12}
              sm={12}
              md={7}
              lg={7}
            >
              <p>
                Copyright © 2021 Todos los derechos reservados. Banco PagaTodo
                S.A., Institución de Banca Múltiple,
                <br />
                es una institución autorizada por la Comisión Nacional Bancaria
                y de Valores.
              </p>
            </Col>
            <Col
              className="footer-wrapper__info-2"
              xs={12}
              sm={12}
              md={5}
              lg={5}
            >
              <a
                target="_blank"
                href="/pdfs/tabla_de_comisiones_v_30_10_23.pdf"
                className="footer__menu"
              >
                <u> Consulta aquí costos y comisiones de nuestros productos.</u>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  );
};
export default Footer;
