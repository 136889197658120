import {
  DigitalLife as SectionPersons1, PersonsIconText, SectionPersons2,
  SectionPersons3, SectionPersons4, SectionPersons5
} from 'components';
import React, { Fragment, Suspense, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
//Stylesheet
import'styles/persons.scss';

function Persons () {
  const [linkApp, setLinkApp] = useState('');
  const { hash } = useLocation();
  
  useEffect(() => {
    document.body.className='persons';
    if (!hash) {
      setTimeout(() => { scrollTo(0, 0);}, 100);
    }
  }, [])

  useEffect(() => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera || navigator.platform;
    switch(true){
      case /android/i.test(userAgent):  
        setLinkApp("https://onelink.to/5uj5a5");
      break;

      case /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream:  
        setLinkApp("https://onelink.to/5uj5a5");
      break;

      case /Macintosh/.test(userAgent) && !window.MSStream:  
        setLinkApp("https://onelink.to/5uj5a5");
      break;

      default: 
        setLinkApp("https://play.google.com/store/apps/details?id=com.pagatodo.yaganaste")
      break
    }
  },[])

  return (
      <Fragment>
        <Suspense fallback={<></>}>
          <Container>
            <SectionPersons1 />
            <PersonsIconText />
            <SectionPersons2 linkApp={linkApp}/>
          </Container>
          <SectionPersons3 linkApp={linkApp}/>
          <SectionPersons4 linkApp={linkApp}/>
          <SectionPersons5 linkApp={linkApp}/> 
        </Suspense>
      </Fragment>
  );
}
export default Persons;
