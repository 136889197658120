import React, { useContext, useEffect, useState } from 'react';
import { Container, Button, Navbar as NavbarB, Nav, Offcanvas } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import Logo from 'images/logo.png';
import { AppContext } from 'context/AppContext';
import { HashLink } from 'react-router-hash-link';

const links = [
  {
    path: '/',
    description: 'Personas'
  },
  {
    path: '/empresas',
    description: 'Empresas'
  },
  // {
  //   path: '/serviciosfintech',
  //   description: 'Fintechs'
  // }
];

const expand = 'lg';

const Navbar = ({ dark }) => {
  const { path } = useContext(AppContext);
  const [linkApp, setLinkApp] = useState('');
  const [isWeb, setIsWeb] = useState(window.innerWidth > 992);
  const [expanded, setExpanded] = useState(false);

  const { pathname } = useLocation();  

  const resize = () => {
    // initializing expanded if screen is not mobile and expanded is showed
    if (window.innerWidth < 992) {
      setIsWeb(false);
      if (expanded)
        setExpanded(false);
    } else {
      setIsWeb(true);
    }
  }

  useEffect(() => {
    let userAgent = navigator.userAgent || navigator.vendor || window.opera || navigator.platform;
    switch(true){
      case /android/i.test(userAgent):  
        setLinkApp("https://onelink.to/5uj5a5");
      break;

      case /iPad|iPhone|iPod/.test(userAgent) && !window.MSStream:  
        setLinkApp("https://onelink.to/5uj5a5");
      break;

      case /Macintosh/.test(userAgent) && !window.MSStream:  
        setLinkApp("https://onelink.to/5uj5a5");
      break;

      default: 
        setLinkApp("https://play.google.com/store/apps/details?id=com.pagatodo.yaganaste")
      break
    }
  },[])

  useEffect(() => {
    // initializing resize event
    window.addEventListener("resize", resize);
    return () => window.removeEventListener("resize", resize);
  }, [screen.innerWidth])

  useEffect(() => {
    // initializing resize event
    setExpanded(false);
  }, [pathname])

  return (
    <NavbarB
      expanded={expanded}
      onToggle={() => setExpanded(!expanded)}
      bg={dark ? 'dark' : 'light'} variant={dark ? 'dark' : 'light'}  className={dark ? 'dark-navbar' : ''} sticky='top'
      expand={'lg'}
    >
      <Container>
        <NavbarB.Brand as={Link} to='/'>
          <img src={Logo} alt='Logo' className='d-inline-block align-top' />
        </NavbarB.Brand>
        <NavbarB.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} />
        {/* mobile menu */}
        {!isWeb && 
        <NavbarB.Offcanvas
          id={`offcanvasNavbar-expand-${expand}`}
          aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
          placement="end"
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
              {' '}
            </Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
          <Nav className='me-auto'>
            {links.map((item, index) => (
              <Nav.Link key={index} as={Link} to={item.path} active={path === item.path}>{item.description}</Nav.Link>
            ))}
          </Nav>
          {
            path === '/serviciosfintech' ?
              <Button style={{ width: '10rem' }} className='nav-button primary-btn inline' as={HashLink} to='#form_fintech'>
                Contáctanos
              </Button> 
            :
              <a target='_blank' rel="noreferrer" href={linkApp}>
                <Button style={{ width: '10rem' }} className='nav-button primary-btn inline' >
                  Únete
                </Button>
              </a>
          }
          </Offcanvas.Body>
        </NavbarB.Offcanvas>}
        {/* web menú */}
        {isWeb &&
        <NavbarB.Collapse id="responsive-navbar-nav">
          <Nav className='me-auto'>
              {links.map((item, index) => (
                <Nav.Link key={index} as={Link} to={item.path} active={path === item.path}>{item.description}</Nav.Link>
              ))}
            </Nav>
            {
              path === '/serviciosfintech' ?
                <Button style={{ width: '10rem' }} className='nav-button primary-btn inline' as={HashLink} to='#form_fintech'>
                  Contáctanos
                </Button> 
              :
                <a target='_blank' rel="noreferrer" href={linkApp}>
                  <Button style={{ width: '10rem' }} className='nav-button primary-btn inline' >
                    Únete
                  </Button>
                </a>
            }
        </NavbarB.Collapse>}
      </Container>
    </NavbarB>
  )
}
Navbar.propTypes = {
  dark: PropTypes.bool
}
export default Navbar;
