import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import image from 'images/company/web-banking.png';
import Icon1 from 'images/img-1-banking.png';
import Icon2 from 'images/img-2-banking.png';
import Icon3 from 'images/img-3-banking.png';
import { Fragment } from 'react';
//Stylesheet
import 'styles/empresas_theme.scss'
import { IconText } from 'components';
import { useLocation } from 'react-router-dom';

const data = [
  {
    title: 'Dispersión Masiva',
    description: <Fragment>Transferencias multi-banco<br />en un click​​</Fragment>,
    icon: Icon1
  },
  {
    title: 'Travel & Expense',
    description: <Fragment>Tarjetas empresariales para<br />todos tus colaboradores​</Fragment>,
    icon: Icon2
  },
  {
    title: 'Control Total',
    description: <Fragment>Gestión centralizada <br />en tus manos​</Fragment>, 
    icon: Icon3
  }
]

export default function Company () {
  const { hash } = useLocation();

  useEffect(() => {
    document.body.className='company';
    if (!hash) {
      setTimeout(() => { scrollTo(0, 0);}, 100);
    }
  }, [])

  return (
    <Container>
      <Row id='webbanking' style={{ padding: '7em 0'}}>
          <Col className='hero-col' xs={12} sm={12} md={6} lg={6} xl={5}>
            <div>
              <h1>PagaTodo</h1>
              <h1>Web Banking</h1>
              <br />
              <h2 style={{ marginBottom: 10 }}>Ordena tus Finanzas</h2>
            </div>
          </Col>
          <Col className='text-center' xs={12} sm={12} md={6} lg={6} xl={7}>
            <img src={image} style={{ width: '100%' }} alt='personas_lp1' />
          </Col>
      </Row>
      <IconText
        data={data}
        rowClassName='icon-text-company'
        colClassName='icon-text-company__wrapper'
      />
    </Container>
  )
}
