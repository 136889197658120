import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Input = ({ label, text, labelClassName, groupClassName, controlId, textClassName, ...props }) => {
    return (
        <Form.Group className={groupClassName} controlId={controlId}>
            <Form.Label className={labelClassName}>{label}</Form.Label>
            <Form.Control {...props} />
            <Form.Text className={textClassName}>{text}</Form.Text>
        </Form.Group>
    )
}
Input.propTypes = {
    label: PropTypes.any,
    text: PropTypes.any,
    labelClassName: PropTypes.string,
    groupClassName: PropTypes.string,
    controlId: PropTypes.string,
    textClassName: PropTypes.string
}
export default Input;