import {
  FintechsIconText, SectionFintechs1, SectionFintechs2,
  SectionFintechs3, SectionFintechs4, SectionFintechs5
} from 'components';
import React, { Fragment, useEffect } from 'react';
import { Container } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

//Stylesheet
import 'styles/fintechs_theme.scss';

export default function ServiciosFintech() {
  const { hash } = useLocation();

  useEffect(() => {
    document.body.className='fintechs';
    if (!hash) {
      setTimeout(() => { scrollTo(0, 0);}, 100);
    }
  }, [])

  return (
    <Fragment>
      <Container>
        <SectionFintechs1 />
        <FintechsIconText />
      </Container>
      <SectionFintechs2 />
      <SectionFintechs3 />
      <SectionFintechs4 />
      <SectionFintechs5 />
    </Fragment>
  )
}
