import { Navbar } from 'components';
import { AppContext } from 'context/AppContext';
import { useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { getDarkNav, getPathName } from './helpers/pathClass';

import 'styles/Main.scss';

const NotFoundLayout = () => {
    const { path } = useContext(AppContext);

    return (
        <div className={getPathName(path)}>
            <Navbar dark={getDarkNav(path)} />
            <Outlet />
        </div>
    )
}
export default NotFoundLayout;