import { Col, Container, Row } from 'react-bootstrap';
import ContactForm from './ContactForm';
//Stylesheet
import'styles/about-contact.scss';
import { useEffect } from 'react';

const contactList = [
    {
        title: 'Reportar como extravíada o robada tu tarjeta'
    },
    {
        title: 'Realizar alguna aclaración'
    },
    {
        title: 'Información, dudas o comentarios sobre nuestros productos y servicios'
    }
]

const Contact = () => {
    useEffect(() => {
        document.body.className='persons';
    }, [])
    
    return (
        <main style={{ width: '100vw', paddingBottom: 40 }}>
            <Container id='contact' style={{ height: '90%', paddingTop: '5.5rem' }}>
                <Row className={'mb-5'}>
                    <Col className={'text-center'} xs={12} sm={12} md={12} lg={12}>
                        <h1>Queremos escucharte</h1>
                        <h4>¿Estás interesado en alguna de nuestras soluciones?</h4>
                        <h4>Déjanos tus datos y te contactaremos.</h4>
                    </Col>
                </Row>
                <ContactForm />
                <Row>
                    <Col xs={12} sm={12} md={6} lg={5}>
                        <h3 className='mb-2'>Línea Banco PagaTodo</h3>
                        <p>
                            Envíanos un mail a: aclaraciones@pagatodo.com 
                        </p>
                        <p className='mb-3'>
                            Contáctanos al 800 500 1000 para:
                        </p>
                        <ul className='contact'>
                            {contactList.map((item,index) => <li key={index}>{item.title}</li>)}
                        </ul>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={{ offset: 2, span: 5}}>
                        <h3 className='mb-2'>Visítanos</h3>
                        <p className='mb-3'>Puedes visitarnos de Lunes a Viernes en un horario de 09:00 a 18:00</p>
                        <p>
                            Boulevard Manuel Ávila Camacho No. 66 Centro Comercial Lomas Plaza, Piso 2 Col. Lomas Chapultepec 
                            11000, Ciudad de México
                        </p>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}
export default Contact;