function useValidation () {
    const emailValidation = (value) => {
        const regex = /^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\],;:\s@"]{2,63}$/
        return !!regex.test(value)
    }

    const numberValidation = (value) => {
        const regex = /^\d+$/
        return !!regex.test(value)
    }
     
    const wordValidation = (value) => {
        const regex = /^[a-zA-Z ¨´áéíóúñüÁÉÍÓÚÑÜ]*$/
        return !!regex.test(value)
    }

    return {
        emailValidation,
        numberValidation,
        wordValidation
    }
}
export default useValidation;
