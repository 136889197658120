import React from 'react';
 
import 'styles/finance.scss'
const FinancialInformation = () => {
  return (
    <div className='banner-faq' id='finance'>
        <h1>{'Información financiera'}</h1>
    </div>
  )
}

export default FinancialInformation
