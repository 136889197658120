import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Card } from "components";
import { Card as BCard } from "react-bootstrap";

//Stylesheet
import "styles/empresas_theme.scss";

const data = [
  {
    title: "Cuenta Útil ",
    description: "Sin límite de saldo, ni monto mínimo de apertura.",
    url: "/pdfs/Productos/contrato_cuenta_util_pagina_web_30-10-23.pdf",
    text: "Más información",
  },
  {
    title: "Cuenta Ya Ganaste",
    description: "Sin límite de saldo, ni monto mínimo de apertura.",
    url: "/pdfs/Productos/ContratoYaGanaste(30-agosto-2024).pdf",
    text: "Más información",
  },
  {
    title: "Cuenta Fundadores",
    description: "La información es solo para consulta de clientes existentes.",
    url: "/pdfs/Productos/Cuenta_Fundadores_07-23.pdf",
    text: "Más información",
  },
  {
    title: "Cuenta Básica General",
    description: "Producto regulatorio de débito.",
    url: "/pdfs/Productos/Cuenta_BasicaGeneral.pdf",
    text: "Más información",
  },
  {
    title: "Cuenta Básica de Nómina",
    description: "Producto regulatorio de débito.",
    url: "/pdfs/Productos/Cuenta_BasicadeNomina.pdf",
    text: "Más información",
  },
];

const dataCard = [
  {
    title: "Cuenta Corporativa",
    description: "Administrar tus ingresos, compras y pagos.",
    url: "/pdfs/Productos/Cuenta_Corporativa.pdf",
    text: "Más información",
  },
];

const dataProductSNotice = [
  {
    title: "Jul/2023",
    url: "/pdfs/Productos/Aviso_Contratos_Productos_Jul_2023.pdf",
  },
  {
    title: "Oct/2023",
    url: "/pdfs/Productos/Aviso_Contratos_Productos_Oct_2023.pdf",
  },
];

export default function Products() {
  useEffect(() => {
    document.body.className = "persons";
  }, []);

  function handleProductNoticeChange() {
    const select = document.getElementById("productNotice");
    const selectedOption = select.options[select.selectedIndex];
    const url = selectedOption.value;
    window.open(url, "_blank");
  }

  return (
    <main style={{ width: "100vw", paddingBottom: 40 }}>
      <Container id="products" style={{ height: "90%", paddingTop: "5.5rem" }}>
        <h1 className={"text-center mb-5"}>Productos</h1>
        <Row>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <h4 className={"text-center"}>Personas</h4>
            <Row>
              <Col>
                {data.map((item, index) => (
                  <Card className="my-4  card cardWidth" key={index}>
                    <BCard.Body style={{ padding: "1rem" }}>
                      <h2 className="title">{item.title} </h2>
                      <p className=" mt-2 mb-4 subTitle">
                        {" "}
                        {item.description}{" "}
                      </p>
                      <a
                        href={item.url}
                        target="_blank"
                        style={{ color: "#55E6C3" }}
                        rel="noreferrer"
                        className="more"
                      >
                        {item.text}
                      </a>
                    </BCard.Body>
                  </Card>
                ))}
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <h4 className={"text-center"}>Empresas</h4>
            <Row>
              <Col>
                {dataCard.map((item, index) => (
                  <Card className="my-4 card cardBlack" key={index}>
                    <BCard.Body style={{ padding: "1rem" }}>
                      <h2 className="title" style={{ color: "#ffffff" }}>
                        {item.title}{" "}
                      </h2>
                      <p
                        className=" mt-2 mb-4 subTitle"
                        style={{ color: "#ffffff" }}
                      >
                        {" "}
                        {item.description}{" "}
                      </p>
                      <a
                        href={item.url}
                        target="_blank"
                        style={{ color: "#55E6C3" }}
                        rel="noreferrer"
                        className="more"
                      >
                        {item.text}
                      </a>
                    </BCard.Body>
                  </Card>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ width: "100%", textAlign: "right" }}>
          <label
            style={{ color: "#55E6C3", paddingRight: "5px" }}
            htmlFor="productNotice"
          >
            Aviso Productos
          </label>
          <select
            id="productNotice"
            onChange={handleProductNoticeChange}
            style={{
              borderRadius: "5px",
              padding: "5px",
              borderColor: "#55E6C3",
              backgroundColor: "#ffffff",
            }}
          >
            <option value="" selected hidden>
              Selecciona documento
            </option>
            {dataProductSNotice.map((item, index) => (
              <option key={index} value={item.url}>
                {item.title}
              </option>
            ))}
          </select>
        </div>
      </Container>
    </main>
  );
}
