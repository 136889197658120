import { HeaderTitle } from 'components';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
//images
import SectionImage from 'images/fintech_section3.png';

const SectionPersonas2 = () => {
    return (
        <section id='emision' style={{ padding: '5rem 0', background: '#233430' }}>
            <Container>
                <Row>
                    <Col className='col-img'
                        xs={{ order: 2, span: 12 }} sm={{ order: 2, span: 12 }} 
                        md={{ order: 1, span: 6 }} lg={{ order: 1, span: 6 }} 
                        xl={{ order: 1, span: 6 }}>
                        <img src={SectionImage} className='hero-fint-img' alt='fintech_s1' />
                    </Col> 
                    <Col className='hero-col mb-3'
                        xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 12 }} 
                        md={{ order: 1, span: 6 }} lg={{ order: 1, span: 6 }} 
                        xl={{ order: 1, span: 6 }}>
                        <HeaderTitle
                            className='mb-2'
                            title={<h1 className='mb-0 p-0' style={{ color: '#55E6C3' }}>Emisión</h1>}
                            subtitle={<h3 className='mb-0' style={{ color: '#55E6C3' }}>Apertura instantánea <br /> de cuentas y tarjetas</h3>}
                        />
                        <br/>
                        <p className='mb-3 text-web'>
                            Integración embebida de nuestro plug-in <br />
                            para la captura en directo del KYC del cliente <br />
                            final, permitiendo la emisión de una tarjeta virtual<br />al
                            instante. <br />
                            La tarjeta plástica (companion card) podrá <br />
                            solicitarse bajo demanda
                        </p>
                        <p className='mb-3 text-mobile'>
                            Integración embebida de nuestro <br />
                            plug-in para la captura en directo del <br />
                            KYC del cliente final, permitiendo la <br />
                            emisión de una tarjeta virtual al instante. <br />
                            La tarjeta plástica (companion card) <br />
                            podrá solicitarse bajo demanda
                        </p>
                        <p className='mb-3 text-tablet'>
                            Integración embebida de nuestro 
                            plug-in para la captura en directo del
                            KYC del cliente final, permitiendo la 
                            emisión de una tarjeta virtual al instante. 
                            La tarjeta plástica (companion card)
                            podrá solicitarse bajo demanda
                        </p>
                        <br/>
                        <Button size='lg' as={HashLink} to='#form_fintech' className='primary-btn btn-large inline fint-btn' style={{ color: '#253632'}}>
                        Conoce Más
                        </Button>
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default SectionPersonas2
