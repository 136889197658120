import { HeaderTitle } from 'components';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
//images
import SectionImage from 'images/polygon-fintechs-2.png';

const SectionPersonas4 = () => {
  return ( 
    <section id='moneyinout' style={{ padding: '5rem 0', background: 'linear-gradient(rgb(0 38 33), rgb(37, 93, 84))' }}>
      <Container>
        <Row>
            <Col className='col-img'
              xs={{ order: 2, span: 12 }} sm={{ order: 2, span: 12 }} 
              md={{ order: 1, span: 6 }} lg={{ order: 1, span: 6 }} 
              xl={{ order: 1, span: 6 }}>
                <img src={SectionImage} className='hero-fint-img' alt='fintech_s1' />
            </Col> 
            <Col className='hero-col mb-3'
              xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 12 }} 
              md={{ order: 1, span: 5 }} lg={{ order: 1, span: 5 }} 
              xl={{ order: 1, span: 5 }}>
          <HeaderTitle
            className='mb-2'
            title={<h1 className='mb-0 p-0' style={{ color: '#55E6C3' }}>Money<br /> In/Out</h1>}
            subtitle={<h3 className='mb-0' style={{ color: '#55E6C3' }}>Concentración y<br /> dispersión de fondos</h3>}
          />
            <br/>
            <p className='mb-3 text-web'>
              Utiliza la misma referencia para recibir pagos <br /> 
              en efectivo o por transferencia.<br />
              Nuestro motor gestiona tus dispersiones <br />
              individuales, programadas o bajo demanda (H2H)
            </p>
            <p className='mb-3 text-mobile'>
              Utiliza la misma referencia para recibir <br /> 
              pagos en efectivo o por transferencia.<br />
              Nuestro motor gestiona tus dispersiones <br />
              individuales, programadas o <br />
              bajo demanda (H2H)
            </p>
            <p className='mb-3 text-tablet'>
              Utiliza la misma referencia para recibir  
              pagos en efectivo o por transferencia. 
              Nuestro motor gestiona tus dispersiones 
              individuales, programadas o 
              bajo demanda (H2H)
            </p>
            <br/>
            <Button size='lg' as={HashLink} to='#form_fintech' className='primary-btn btn-large inline fint-btn'>
                Conoce Más
            </Button>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
export default SectionPersonas4;
