import { Layout, NotFoundLayout } from 'components';
import { Navigate, Route, Routes } from 'react-router-dom';
import Contact from 'pages/contacto';
import Empresas from 'pages/empresas';
import QandA from 'pages/faqs';
import Finance from 'pages/finance';
import ServiciosFintech from 'pages/fintech';
import AboutUs from 'pages/nosotros';
import { Buro, LegalNotice, /* PrivacyNotice, */ SecurityNotice, Products, Une } from 'pages/notices';
import Persons from 'pages/personas';
import { Container } from 'react-bootstrap';

export default function AppRouter () {
    return (
        <Routes>
            <Route element={<Layout />}>
                <Route path='/' element={<Persons />} />
                <Route path='/avisolegal' element={<LegalNotice />} />
                {/* <Route path='/avisoprivacidad' element={<PrivacyNotice />} /> */}
                <Route path='/une' element={<Une />} />
                <Route path='/avisodeseguridad' element={<SecurityNotice />} />
                <Route path='/contacto' element={<Contact />} />
                <Route path='/empresas' element={<Empresas />} />
                <Route path='/informacionfinanciera' element={<Finance />} />
                <Route path='/faqs' element={<QandA />} />
                <Route path='/nosotros' element={<AboutUs />} />
                <Route path='/serviciosfintech' element={<ServiciosFintech />} />
                <Route path='/buro' element={<Buro />} />
                <Route path='/productos' element={<Products />} />
            </Route>
            <Route element={<NotFoundLayout />}>
                <Route path='/not-found' element={ <Container style={{ minHeight: '90vh'}}>
                    <h1>Página no encontrada</h1>
                </Container>} />
                <Route path='*' element={<Navigate to='/not-found' />} />
            </Route>
        </Routes>
    )
}