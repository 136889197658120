import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';

const IconText = ({ data, rowClassName, colClassName }) => {
  return (
    <Row as='section' className={`${rowClassName}`} >
      {data.map((item, index) => (
        <Col className={colClassName} key={index} xs={12} sm={12} md={12} lg={4} xl={4}>
          <img src={item.icon} alt={`icon${index+1}`} />
          <h2>{item.title}</h2>
          <p>{item.description}</p>
        </Col>
      ))}
      <span id='emision' ></span >
    </Row>
  )
}
IconText.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        item: PropTypes.any,
        title: PropTypes.any,
        description: PropTypes.any
    })),
    colClassName: PropTypes.string,
    rowClassName: PropTypes.string
}
export default IconText;
