import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const data = [
    {
        title: 'Condiciones de Uso',
        path: '#condicionesuso'
    },
    {
        title: 'Confidencialidad',
        path: '#confidencialidad'
    },
    {
        title: 'Sitios Vinculados',
        path: '#sitiosvinculados'
    },
    {
        title: 'Bienes y Servicios de Terceros Enlazados',
        path: '#bienesservicios'
    },
    {
        title: 'Fallas en el Sistema',
        path: '#fallassistema'
    },
    {
        title: 'Propiedad Intelectual e Industrial',
        path: '#prioridadintelectual'
    },
    {
        title: 'Responsabilidad',
        path: '#responsabilidad'
    },
    {
        title: 'Sanciones',
        path: '#sanciones'
    },
    {
        title: 'Privacidad Mailing BANCO PAGATODO México',
        path: '#privacidadmailing'
    },
    {
        title: 'Jurisdicción Aplicable',
        path: '#jurisdiccion'
    },
    {
        title: 'Seguridad',
        path: '#seguridad'
    },
];

const LegalNotice = () => {
    useEffect(() => {
        document.body.className='persons';
    }, [])
    
    return (
        <main style={{ width: '100vw', paddingBottom: 40 }}>
            <Container id='legal' style={{ height: '90%', paddingTop: '5.5rem' }}>
                <Row className={'mb-5 w-75 m-auto'}>
                    <Col id='inicio' className={'text-center'} xs={12} sm={12} md={12} lg={12}>
                        <h1>Aviso Legal</h1>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <h6>Términos y Condiciones www.BancoPagaTodo.com</h6>
                        <p>El servicio que se presta a través de la presente página de Internet, ubicada en www.Banco PagaTodo.com, en adelante denominado como el “Sitio”, es provisto por BANCO PAGATODO, S.A., INSTITUCIÓN DE BANCA MÚLTIPLE, al que podrá tener acceso cualquier persona y clientes de BANCO PAGATODO que hayan celebrado previamente con BANCO PAGATODO el contrato correspondiente para realizar transacciones de productos y servicios financieros ofrecidos en el Sitio (el “Usuario”).</p>
                    </Col>
                    <Col className='my-2 pt-4' xs={12} sm={12} md={12} lg={12}>
                        <ul>
                            {data.map((item, index) => (
                                <li key={index}><a href={item.path}>{item.title}</a></li>
                            ))}
                        </ul>
                    </Col>
                    <Col id='condicionesuso' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Condiciones de Uso</h6>
                        <p>El simple hecho de acceder al Sitio, implica que el Usuario está aceptando expresamente los términos y condiciones que aquí se describen.</p>
                        <br />
                        <p>BANCO PAGATODO, se reserva el derecho de modificar el contenido del Sitio en cualquier momento, sin necesidad de previo aviso.</p>
                        <br />
                        <p>El Usuario conviene en no utilizar dispositivos, software, o cualquier otro medio tendiente a interferir tanto en las actividades y/u operaciones del Sitio y/o de BANCO PAGATODO, o en las bases de datos y/o información que se contenga en el mismo.</p>
                        <p>El Usuario conviene en que cualquier intromisión, tentativa o actividad violatoria o contraria a la leyes sobre derechos de autor, de propiedad intelectual, secreto bancario, y/o a las prohibiciones aquí estipuladas, lo harán responsable, y BANCO PAGATODO tendrá el derecho de ejercitar las acciones legales pertinentes en su contra. Asimismo, en tal caso el Usuario será responsable de indemnizar los daños y perjuicios ocasionados a BANCO PAGATODO.</p>
                        <p>El Usuario conviene en que las violaciones de seguridad a los sistemas de cómputo y/o redes están prohibidas, y pueden generar delitos o incurrir en responsabilidad civil.</p>
                        <p>El Sitio es para uso exclusivo del Usuario, por lo que éste no podrá lucrar de manera alguna con los servicios financieros ofrecidos en el Sitio.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='confidencialidad' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Confidencialidad</h6>
                        <p>Toda la información que BANCO PAGATODO recabe del Usuario es tratada con absoluta confidencialidad conforme las disposiciones legales aplicables.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='sitiosvinculados' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Sitios Vinculados</h6>
                        <p>Cualquier vinculación con otro sitio, ya sea mediante ligas (links), marcos (frames) o de cualquier otra forma, que no se encuentre autorizada expresamente por BANCO PAGATODO, será prohibida, por lo que BANCO PAGATODO se reserva el derecho de desactivar del Sitio cualquier liga o marco no autorizado. De la misma forma, BANCO PAGATODO no asume responsabilidad alguna respecto del contenido de cualquier sitio ligado o vinculado con el Sitio. Asimismo, el Usuario reconoce que cualquier acceso a otro sitio vinculado o ligado, será bajo su exclusiva responsabilidad, y en ese acto libera a BANCO PAGATODO de cualquier responsabilidad derivada de cualquier asunto relacionado con dicho acceso.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='bienesservicios' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Bienes y Servicios de Terceros Enlazados</h6>
                        <p>En relación con la información de los bienes y/o servicios que se ofrecen a través del Sitio por sitios de terceros ligados o vinculados al Sitio, éstos son suministrados por personas físicas o morales independientes a BANCO PAGATODO, siendo responsabilidad exclusiva del proveedor que los ofrece, por lo cual bajo ninguna circunstancia BANCO PAGATODO podrá ser considerado como el proveedor, vendedor o prestador de dichos bienes y/o servicios, por lo que en este acto el Usuario libera a BANCO PAGATODO de cualquier asunto relacionado con lo anterior, y BANCO PAGATODO no asume responsabilidad alguna por cualquier daño que se pudiere causar a cualquier Usuario del Sitio, en relación con el contenido del presente párrafo.</p>
                        <p>El hecho de que se ofrezca información en el Sitio o en sitios ligados o vinculados, no implica la recomendación, garantía, patrocinio o aprobación por parte de BANCO PAGATODO respecto dicha información, bienes y/o servicios. La disponibilidad de bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados, no es responsabilidad de BANCO PAGATODO. En vista de lo anterior, BANCO PAGATODO no será responsable ante cualquier autoridad de cualquier naturaleza, por cualquier asunto relacionado con la venta, consumo, distribución, entrega, disponibilidad o prestación con respecto de cualquiera de los bienes y/o servicios ofertados por terceros o por sitios ligados o vinculados a través del Sitio.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='fallassistema' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Fallas en el Sistema</h6>
                        <p>BANCO PAGATODO no se responsabiliza por cualquier daño, perjuicio o pérdida en el equipo del Usuario causada por fallas en los sistemas de BANCO PAGATODO, en su servidor, o en la Internet. Asimismo, BANCO PAGATODO no será responsable por cualquier virus que pudiera infectar el equipo del Usuario como consecuencia del acceso, uso o examen del Sitio o a raíz de cualquier transferencia de datos, archivos, imágenes, textos, o audio contenidos en el mismo.</p>
                        <p>Los Usuarios no podrán imputar a BANCO PAGATODO responsabilidad alguna ni exigirle a éste indemnización por daños o perjuicios, resultantes de dificultades técnicas o fallas en los sistemas de BANCO PAGATODO, o en los medios electrónicos como la Internet.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='prioridadintelectual' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Propiedad Intelectual e Industrial</h6>
                        <p>Los programas, bases de datos, redes y archivos que permiten al Usuario acceder y usar el Sitio, son propiedad de BANCO PAGATODO y están protegidos por las leyes y tratados internacionales de derechos de autor, marcas, patentes, modelos y diseños industriales. El uso indebido y/o la reproducción total o parcial del contenido de los mismos queda prohibido, y dichas acciones se encuentran sujetas a sanciones civiles y penales, y serán objeto de todas las acciones judiciales pertinentes.</p>
                        <br />
                        <p>Cualquier Usuario que tenga acceso al Sitio o a la información contenida en el mismo, reconoce expresamente que BANCO PAGATODO, y/o empresas afiliadas y/o relacionadas, son los titulares, propietarios, y/o legítimos licenciatarios, de cualquier información que se encuentre en el Sitio, así como de cualquier marca, aviso, nombre comercial o cualquier otro derecho de propiedad industrial o derecho de autor que aparezca en el Sitio. Asimismo, el Usuario reconoce que BANCO PAGATODO ha sido el desarrollador del concepto de Banco PagaTodo.com.mx, por lo que es del conocimiento de dicho Usuario que dicha información y/o concepto se encuentran protegidos por las leyes mexicanas y por los tratados internacionales de derechos de autor, por lo que cualquier reproducción, modificación, alteración, aprovechamiento o cualquier otro uso que se le dé a dicha información y/o concepto, se encuentra prohibido, y en este acto el Usuario del Sitio o cualquier persona que tenga acceso a la información antes señalada, se obliga a indemnizar a BANCO PAGATODO y/o cualquier empresa afiliada y/o relacionada, con respecto de cualquier violación a lo estipulado en el presente párrafo, ya sea en México o en el extranjero.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='responsabilidad' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Responsabilidad</h6>
                        <p>BANCO PAGATODO no asume ninguna responsabilidad relacionada o derivada con la veracidad de cualquier información contenida en el Sitio proporcionada por cualquier persona física o moral distinta a BANCO PAGATODO. De la misma forma, BANCO PAGATODO no asume ninguna responsabilidad con respecto a cualquier daño o perjuicio que se pudiere llegar a causar a cualquier Usuario, en relación con la utilización de cualquier información contenida en el Sitio, distinta de la información proporcionada por BANCO PAGATODO.</p>
                        <p>En caso que el Usuario desee realizar transacciones financieras a través del Sitio y haya recibido de BANCO PAGATODO los medios de seguridad previamente establecidos, es responsabilidad del Usuario mantener de forma confidencial dicha información, por lo que BANCO PAGATODO no es responsable por errores o negligencia del Usuario al utilizar el Sitio.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='sanciones' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Sanciones</h6>
                        <p>BANCO PAGATODO se reserva el derecho de condicionar, suspender o cancelar, temporal o definitivamente el acceso al Sitio, a un Usuario en cualquier momento, e iniciar las acciones legales que correspondan, si el Usuario quebranta cualquiera de las estipulaciones de los términos y condiciones aquí establecidos, o comete cualquier otro acto contrario a las leyes aplicables vigentes, o bien si no pudiera verificarse la identidad del Usuario, o cualquier información proporcionada por el mismo fuere falsa.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='privacidadmailing' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-3'>Privacidad en Mailing BANCO PAGATODO México</h6>
                        <p>Lea lo siguiente para entender el tratamiento que damos a su información personal cuando utiliza nuestros servicios.</p>
                        <br />
                        <p>Qué puntos abarcan las Políticas de Privacidad</p>
                        <ul>
                            <li>Esta Política de Privacidad habla del uso que le damos a la información personal que el Cliente proporciona exclusivamente a BANCO PAGATODO México cuando utiliza nuestros servicios.</li>
                        </ul>
                        <h6 className='mb-3'>Recopilación y Uso de Información</h6>
                        <ul>
                            <li>BANCO PAGATODO podrá invitar, comunicar y notificar a los Usuarios de éste Sitio a través de mailing y banners sobre información y promociones de productos o servicios de BANCO PAGATODO, para visitar sitios dentro de BANCO PAGATODO y poder participar en promociones y/o eventos especiales.</li>
                            <li>BANCO PAGATODO consulta a los Usuarios sobre la siguiente información: preferencia para recibir información de productos y servicios de BANCO PAGATODO; si están de acuerdo en recibir información relevante como temas de seguridad y nuevas medidas de seguridad.</li>
                            <li>BANCO PAGATODO también recibirá y registrará información que automáticamente envíe el navegador que usted utiliza, incluyendo su Dirección IP (Protocolo de Internet), datos del sistema operativo, nombre y versión de su navegador, tipo de conexión, resolución de pantalla, idioma, paleta de colores y versión de Java.</li>
                            <li>BANCO PAGATODO utilizará esta información para personalizar la publicidad y el contenido del correo electrónico de acuerdo al usuario.</li>
                        </ul>
                        <h6>Compartir y revelar información</h6>
                        <ul>
                            <li>BANCO PAGATODO no vende ni renta a nadie información personal de sus usuarios.</li>
                        </ul>
                        <h6>Cookies</h6>
                        <ul>
                            <li>{'BANCO PAGATODO utiliza "cookies"que podrá colocar en su computadora y tener acceso a ellas y que le permitirán iniciar sesión en servicios de BANCO PAGATODO de acuerdo a su experiencia personal en línea, almacenando sus preferencias en su equipo para ahorrarle tiempo, eliminando la necesidad de especificar repetidamente la misma información y solo mostrar contenido personalizado y publicidad adecuada en sus posteriores visitas al Sitio. Un cookie es un archivo de texto situado por un servidor de páginas web en el disco duro de su equipo. Los cookies contienen información que después puede leer un servidor web que pertenece al dominio que emitió el cookie. Los cookies no se pueden usar para ejecutar programas ni infectar con virus su equipo. Usted puede aceptar o rechazar cookies. La configuración de su equipo puede aceptar cookies automáticamente pero si lo prefiere, puede modificar la configuración de su equipo para rechazar los cookies. Si elige rechazar los cookies, no será posible que BANCO PAGATODO almacene sus preferencias en este Sitio.'}</li>
                        </ul>
                        <h6>Acerca de borrar o actualizar información</h6>
                        <ul>
                            <li>BANCO PAGATODO le permite modificar su información y preferencias en el momento que usted quiera, a través de la liga de Preferencias o de suscripción que SIEMPRE se incluye en los correos electrónicos que BANCO PAGATODO envía, (aquí se incluye su decisión de permitir a BANCO PAGATODO contactarle para informarle sobre ciertas promociones o nuevos productos)</li>
                        </ul>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='jurisdiccion' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6>Jurisdicción aplicable</h6>
                        <p>Para todo lo relativo a la interpretación y cumplimiento de los presentes términos y condiciones, las partes se someten a las leyes aplicables y a los Tribunales competentes de la Ciudad de México, D.F., y renuncian a cualquier otra jurisdicción que por razón de sus domicilios presentes o futuros pudiere corresponderles.</p>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                    <Col id='seguridad' className='mb-3 pt-5' xs={12} sm={12} md={12} lg={12}>
                        <h6>Seguridad</h6>
                        <ul>
                            <li>IMPORTANTE: BANCO PAGATODO nunca envía a sus clientes correos electrónicos solicitando confirmar, validar o actualizar información CONFIDENCIAL respecto a sus CUENTAS, CLAVES DE ACCESO, CONTRASEÑAS, NÚMEROS DE TARJETAS o NIPs.</li>
                            <li>Si usted recibe un correo que le solicite estos datos, por favor repórtelo al 800 500 1000.</li>
                            <li>Si usted dudara de la autenticidad de alguno de nuestros correos o bien sospecha de estar recibiendo correos de dudosa procedencia a nombre de BANCO PAGATODO le pedimos nos notifique vía telefónica al 800 500 1000.</li>
                        </ul>
                        <p className='back mt-4 mb-2'><a href='#inicio' >Regresar</a></p>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}
export default LegalNotice;
