import Logo from 'images/logo.png';
import { Container } from "react-bootstrap";
import styles from './Une.module.css';

const Une = () => {
    return (
        <main style={{ width: '100vw', paddingBottom: 40 }}>
            <Container id='une' className={styles.une} style={{ height: '90%', paddingTop: '5.5rem', textAlign: 'center' }}>
                <h1>UNE</h1>
                <div className={styles.container}>
                    <img src={Logo} alt='Logo' className='mb-3' />
                    <h5 className={`${styles.title} m-0`}>Banco PagaTodo, SA, Institución de Banca Múltiple</h5>
                    <p className={`${styles.subtitle} mb-5`}>Unidad Especializada de Atención a Usuarios</p>
                    <div className={styles.group}>
                        <div className={styles['group-title']}>
                            <h6 className={`${styles.title} m-0`}>Titular de la UNE</h6>
                        </div>
                        <div className='pt-2 pb-2'>
                            <p>Alejandro Maqueda Maya</p>
                        </div>
                    </div>
                    <div className={styles.group}>
                        <div className={styles['group-title']}>
                            <h6 className={`${styles.title} m-0`}>Domicilio</h6>
                        </div>
                        <div className='pt-2 pb-2'>
                            <p>Blvd. Manuel Ávila Camacho No. 66 Piso 2</p>
                            <p>Lomas de Chapultepec, CP: 11000</p>
                            <p>Miguel Hidalgo, Ciudad de México</p>
                        </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        <div className={`${styles.group} ${styles['group-width']}`}>
                            <div className={styles['group-title']}>
                                <h6 className={`${styles.title} m-0`}>Teléfono</h6>
                            </div>
                            <div className='pt-2 pb-2'>
                                <p>55 5249 5030</p>
                            </div>
                        </div>
                        <div className={`${styles.group} ${styles['group-width']}`}>
                            <div className={styles['group-title']}>
                                <h6 className={`${styles.title} m-0`}>Correo Electrónico</h6>
                            </div>
                            <div className='pt-2 pb-2'>
                                <p>unebanco@pagatodo.com</p>
                            </div>
                        </div>
                    </div>
                    <div className={`${styles.schedule} p-3`}>
                        <p className='mb-2'>Horario de Atención</p>
                        <h6 className={styles.title}>Lunes a Jueves de 9:00 a 17:00 hrs</h6>
                        <h6 className={styles.title}>Viernes de 9:00 a 15:00 hrs</h6>
                    </div>
                    <div className={styles.divider}/>
                    <div style={{ textAlign: 'justify'}}>
                        <p className={styles['footer-p']}>Banco PagaTodo, Sa, Institución de Banca Múltiple, no cuenta con sucursales u oficinas de atención al publico en el interior de la República Mexicana</p>
                        <p className={styles['footer-p']}>Sin embargo el encargado regional nombrado para atender cualquier asunto relacionado con la UNE es Alejandro Maqueda Maya</p>
                        <p className={styles['footer-p']}>En caso de alguna consulta, reclamación o aclaración, podrá presentarla en la Unidad Especializada de Atención a Usuarios, misma que dará respuesta en un plazo no mayor a 30 días hábiles</p>
                    </div>
                    <div className={styles.divider}/>
                </div>
            </Container>
        </main>
    )
}
export default Une;