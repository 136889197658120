import { Col, Row } from 'react-bootstrap';

const BannerQandA = () => {
    return ( 
        <Row className={'mb-5'}>
            <Col className={'text-center'} xs={12} sm={12} md={12} lg={12}>
                <h1>Preguntas Frecuentes</h1>
                <h4>Todo lo que necesitas saber de PagaTodo</h4>
            </Col>
        </Row>
     );
}
 
export default BannerQandA;