export const getPathName = (path) => {
    switch (path) {
        case '/empresas':
            return 'company-layout';
        case '/serviciosfintech':
            return 'fintechs-layout';
        case '/nosotros':
            return 'aboutus-layout';
        case '/contacto':
            return 'contact-layout';
        case '/':
            return 'persons-layout';
        default:
            return 'common-layout';
    }
}

export const getDarkNav = (path) => {
    if (path === '/empresas' || path === '/serviciosfintech') {
        return true;
    }
    return false;
}