import React, { Fragment } from 'react';
 
//images
import AppStore from 'images/app-store.png';
import AppStoreMobile from 'images/as-mobile.png';
import GooglePlay from 'images/google-play.png';
import GooglePlayMobile from 'images/ps-mobile.png';
import lifestyle from 'images/personas/personas_lp1.png';
import { Row, Col } from 'react-bootstrap';

const SectionImage1 = () => {
    return (
        <Fragment>
            <Row id='hero' style={{ margin: '5rem 0'}}>
                <Col className='text-center' xs={{ order: 2, span: 12 }} sm={{ order: 2, span: 12 }} md={{ order: 1, span: 6 }} lg={{ order: 1, span: 6 }} xl={{ order: 1, span: 6 }}>
                    <img src={lifestyle} style={{ width: '100%' }} alt='personas_lp1' />
                </Col>
                <Col className='hero-col mb-3' xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 12 }} md={{ order: 1, span: 6 }} lg={{ order: 1, span: 6 }} xl={{ order: 1, span: 6 }}>
                    <h1>Protege tus</h1>
                    <h1>Compras</h1>
                    <br />
                    <h2 className='text-web'>El Banco Digital para tu vida digital</h2>
                    <h2 className='text-tablet'>El Banco Digital para <br />tu vida digital</h2>
                    <h2 className='text-mobile'>El Banco Digital para <br />tu vida digital</h2>
                    <br />
                    <div className='apps'>
                        <a
                        className='store-button img-web'
                        target='_blank' rel='noreferrer'
                        href='https://play.google.com/store/apps/details?id=com.pagatodo.yaganaste'
                        >
                            <img src={GooglePlay} alt='google-play' />
                        </a>
                        <a
                        className='store-button img-web'
                        target='_blank' rel='noreferrer'
                        href='https://apps.apple.com/mx/app/banco-paga-todo/id1040556964'
                        >
                            <img src={AppStore} className='pl-2' alt='app-store' />
                        </a>
                        <a
                        className='store-button img-mobile'
                        target='_blank' rel='noreferrer'
                        href='https://play.google.com/store/apps/details?id=com.pagatodo.yaganaste'
                        >
                            <img src={GooglePlayMobile} alt='google-play' />
                        </a>
                        <a
                        className='store-button img-mobile'
                        target='_blank' rel='noreferrer'
                        href='https://apps.apple.com/mx/app/banco-paga-todo/id1040556964'
                        >
                            <img src={AppStoreMobile} className='pl-2' alt='app-store' />
                        </a>
                    </div>
                </Col>
            </Row>
        </Fragment>
    )
}
export default SectionImage1;
