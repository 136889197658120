import { IconText } from 'components';
import * as React from 'react';
//images
import Icon1 from 'images/icon-fintech_1.png';
import Icon2 from 'images/icon-fintech_2.png';
import Icon3 from 'images/icon-fintech_3.png';

const data =[
  {
    title: 'Emisión',
    description: <>Apertura instantánea <br/> de cuentas y tarjetas</>,
    icon: Icon1
  },
  {
    title: 'Aceptación',
    description: <>Banco Adquirente<br/> con tasa natural​</>,
    icon: Icon2
  },
  {
    title: 'Money In / Out',
    description: <>Concentración y <br/>dispersión de fondos</>,
    icon: Icon3
  }
];

const FintechIconText = () => {
  return (
    <IconText
      data={data}
      rowClassName='icon-text icon-text-fint'
      colClassName='icon-text__wrapper'
    />
  )
}

export default FintechIconText
