import emailjs from '@emailjs/browser';
import { Input, MaskInput, Select, TextArea } from 'components';
import useValidation from 'hooks/useValidation';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { markets, industries, sizeCompany } from 'temp/temp';
import { HashLink } from 'react-router-hash-link';
const SectionPersonas5 = () => {
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [company, setCompany] = useState('');
  const [text, setText] = useState('');
  const [market, setMarket] = useState('');
  const [industry, setIndustry] = useState('');
  const [size, setSize] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);
  
  const form = useRef();
  const { emailValidation, wordValidation } = useValidation();

  const handleSubmit = async(e) => {
    try {
      e.preventDefault();
      const response = await emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_TEMPLATE, form.current, process.env.REACT_APP_EMAIL_KEY);
      if (response.text === 'OK') {
        Swal.fire({
          title: 'Proceso Exitoso',
          text: 'Hemos recibido tu información, te contactaremos lo antes posible !',
          icon: 'success'
        });
        setName('');
        setLastName('');
        setEmail('');
        setPhone('');
        setCompany('');
        setText('');
        setMarket('');
        setIndustry('');
        setSize('');
      } else {
        Swal.fire({
          title: 'Proceso Fallido',
          text: 'Tuvimos un problema intentalo más tarde.',
          icon: 'error'
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: `Tuvimos un problema. ${error.message}`,
        icon: 'error'
      });
    }
  }

  useEffect(() => {
    if (name && lastName && phone && phone.replace(/ /g, '').length === 10 && emailValidation(email) && market && industry && size && text) {
      setIsDisabled(false);
    } else  {
      setIsDisabled(true);
    }
  }, [name, email, company, market, text, industry, size, lastName, phone])

  return (
    <section className='form-section' id='form_fintech'>
      <Container>
        <div className='m-0 p-0 text-center'>
          <h1  className='text-center'>Construyamos Soluciones Juntos</h1>
          <h2 >
            ¿Quieres saber más? Estaremos felices de saber de ti, <br/>llena el
            formulario y nos pondremos en contacto contigo
          </h2>
        </div>
        <br/>
        <br/>
        <form className='container' ref={form} onSubmit={isDisabled ? () => {} : handleSubmit}>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <Input
                  size='lg'
                  groupClassName='mb-4'
                  label='Nombre'
                  id='name'
                  name='name'
                  value={name}
                  onChange={(e) => setName(prev => e.target.value ?  wordValidation(e.target.value.trimStart()) ? e.target.value.trimStart() : prev : '')}
                />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Input
                  size='lg'
                  groupClassName='mb-4'
                  label='Apellido'
                  id='lastName'
                  name='lastName'
                  value={lastName}
                  onChange={(e) => setLastName(prev => e.target.value ?  wordValidation(e.target.value.trimStart()) ? e.target.value.trimStart() : prev : '')}
                />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Input
                  size='lg'
                  groupClassName='mb-4'
                  label='Correo de Empresa'
                  id='email'
                  name='email'
                  value={email}
                  onChange={(e) => setEmail(e.target.value ? e.target.value.toLowerCase().trim() : '')}
                />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <MaskInput
                  size='lg'
                  groupClassName='mb-4'
                  label='Número de Teléfono'
                  id='phone'
                  name='phone'
                  value={phone}
                  mask={'00 0000 0000'}
                  onAccept={(e) => setPhone(e ? e : '')}
                />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Select
                  size='lg'
                  groupClassName='mb-4'
                  label='¿Qué solución buscas?'
                  id='country'
                  name='country'
                  value={market}
                  data={markets}
                  onChange={(e) => setMarket(e.target.value)}
                />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Select
                  size='lg'
                  groupClassName='mb-4'
                  label='¿Cual es tu industria?'
                  id='industry'
                  name='industry'
                  data={industries}
                  value={industry}
                  onChange={(e) => setIndustry(e.target.value)}
                />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Input
                  size='lg'
                  groupClassName={'mb-4'}
                  label='Nombre de tu compañía'
                  id='company'
                  name='company'
                  value={company}
                  onChange={(e) => setCompany(e.target.value ? e.target.value.trimStart() : '')}
                />
            </Col>
            <Col sm={12} md={6} lg={6}>
              <Select
                  size='lg'
                  groupClassName='mb-4'
                  label='Tamaño compañía'
                  id='sizeCompany'
                  name='sizeCompany'
                  data={sizeCompany}
                  value={size}
                  onChange={(e) => setSize(e.target.value)}
                />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12}>
              <TextArea
                label='Explica qué problemas estás buscando resolver y qué quieres lograr
                con nuestras soluciones'
                groupClassName='mb-4'
                name='text'
                rows={5}
                value={text}
                onChange={(e) => setText(e.target.value ? e.target.value.trimStart() : '')}
              />
            </Col>
          </Row>
          <Row>
              <Col xs={12} sm={12} md={6} lg={6} className='d-flex align-items-center'>
                <p>Al enviar el formulario aceptas la  <HashLink to='/avisoprivacidad#privacy'   className='footer__menu'>Politica de Privacidad </HashLink></p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={6}>
                <Button disabled={isDisabled} type='submit' size='lg' className='w-100 primary-btn inline fint-btn'>
                  Enviar
                </Button>
              </Col>
          </Row>
        </form>
      </Container>
    </section>
  )
}
export default SectionPersonas5
