//images
import twitter from 'images/twitter.png';
import facebook from 'images/facebook.png';
import instagram from 'images/instagram.png';
import twitterhov from 'images/twitter_hover.png';
import facebookhov from 'images/facebook_hover.png';
import instagramhov from 'images/instagram_hover.png';
import ipab from 'images/ipab.png';
import banco from 'images/banco-mexico.png';
import buro from 'images/buro.png';
import condusef from 'images/condusef.png';
import linkedIn from 'images/linkedIn.png';
import linkedInhov from 'images/linkedIn_hover.png';
export const socialMedia = [
  {
    icon: linkedIn,
    url: 'https://www.linkedin.com/company/pagatodo/',
    iconHov: linkedInhov
  },
  {
    icon: twitter,
    url: 'https://www.twitter.com/bancopagatodo',
    iconHov: twitterhov
  },
  {
    icon: facebook,
    url: 'https://www.facebook.com/BancoPagaTodo',
    iconHov: facebookhov
  },
  {
    icon: instagram,
    url: 'https://www.instagram.com/bancopagatodo/',
    iconHov: instagramhov
  }
]

export const options = [
  {
    title: 'PagaTodo',
    options: [
      {
        path: '/nosotros#us',
        description: 'Nosotros'
      },
      {
        path: '/contacto#contact',
        description: 'Contacto'
      },
      {
        path: '/faqs#faqs',
        description: 'Q & A'
      },
      // {
      //   path: '#',
      //   description: 'Bolsa de Trabajo'
      // },
    ]
  },
  {
    title: 'Personas',
    options: [
      {
        path: "/#tarjetadigital" ,
        description: 'Tarjeta Digital'
      },
      {
        path: "/#suscripciones",
        description: 'Suscripciones'
      },
      {
        path: "/#speiqrcodi",
        description: 'SPEI, QR & CoDi'
      }
    ]
  },
  {
    title: 'Empresas',
    options: [
      {
        path: '/empresas#webbanking',
        description: 'Web Banking'
      }
    ]
  },
  // {
  //   title: 'Fintechs',
  //   options: [
  //     {
  //       path: '/serviciosfintech#emision',
  //       description: 'Emisión'
  //     },
  //     {
  //       path: '/serviciosfintech#aceptacion',
  //       description: 'Aceptación'
  //     },
  //     {
  //       path: '/serviciosfintech#moneyinout',
  //       description: 'Money In/Out'
  //     }
  //   ]
  // },
]

export const organizations = [
  {
    icon: condusef,
    url:'https://www.condusef.gob.mx/'
  },
  {
    icon: banco,
    url:'https://www.banxico.org.mx/'
  },
  {
    icon: ipab,
    url:'https://www.gob.mx/ipab'
  },
  {
    icon: buro,
    url:'https://www.buro.gob.mx/'
  }
];