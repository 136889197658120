import { Form } from "react-bootstrap";
import PropTypes from 'prop-types';

const Select = ({ groupClassName, controlId, label, labelClassName, text, textClassName, data, ...props}) => {
    return (
        <Form.Group className={groupClassName} controlId={controlId}>
            <Form.Label className={labelClassName}>{label}</Form.Label>
            <Form.Select {...props}>
                <option value="">Selecciona una Opción</option>
                {(data.map((item, index) => (
                    <option key={index} value={item.value}>{item.label}</option>
                )))}
            </Form.Select>
        <Form.Text className={textClassName}>{text}</Form.Text>
    </Form.Group>
    )
}
Select.propTypes = {
    label: PropTypes.any,
    text: PropTypes.any,
    labelClassName: PropTypes.string,
    groupClassName: PropTypes.string,
    controlId: PropTypes.string,
    textClassName: PropTypes.string,
    data: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
    }))
}
export default Select;