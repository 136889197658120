import HeaderTitle from 'components/header/HeaderTitle';
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

const SectionPersonas3 = () => {
	return (
		<section
			id='aceptacion'
			className='fintechs-section-3'
			style={{ padding: '7em 0' }}
		>
			<Container>
				<Row>
					<Col className='accept-col' xs={12} sm={12} md={9} lg={7} xl={6}>
						<HeaderTitle
							className='header-fintechs'
							title={<h1 className='mb-0 p-0'>Aceptación</h1>}
							subtitle={
								<h3 className='mb-0'>
									Banco Adquiriente <br />
									con tasa natural​​
								</h3>
							}
						/>
						<br />
						<p className='mb-3'>
							Atención especializada para Agregadores,
							<br />
							Integración a ambas Cámaras
							<br />y miembro principal de Visa y Mastercard
						</p>
						<br />
						<Button
							size='lg'
							as={HashLink}
							to='#form_fintech'
							className='primary-btn btn-large inline fint-btn'
						>
							Conoce Más
						</Button>
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default SectionPersonas3;
