import { FinancialInformation,Documents } from 'components';
 
import React, { Fragment, useEffect } from 'react';
import { Container } from 'react-bootstrap';
//Stylesheet
export default function Finance() {
  useEffect(() => {
    document.body.className='persons';
  }, [])

  return (
    <Fragment>
      <FinancialInformation />
      <Container className='mb-5'>
        <Documents />
      </Container>
  </Fragment>
  )
}
