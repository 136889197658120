import React, { Fragment } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
//images
import SectionImage from 'images/fintech_section1.png';

const SectionImage1 = () => {
  return (
    <Fragment>
    <Row id='hero' className='hero-fint'>
        <Col className='col-img'
          xs={{ order: 2, span: 12 }} sm={{ order: 2, span: 12 }} 
          md={{ order: 1, span: 6 }} lg={{ order: 1, span: 6 }} 
          xl={{ order: 1, span: 6 }}>
            <img src={SectionImage} className='hero-fint-img' alt='fintech_s1' />
        </Col> 
        <Col className='hero-col mb-3'
          xs={{ order: 1, span: 12 }} sm={{ order: 1, span: 12 }} 
          md={{ order: 1, span: 6 }} lg={{ order: 1, span: 6 }} 
          xl={{ order: 1, span: 6 }}>
          <h1 className='mb-0'>{'Acelera tu'}<br style={{lineHeight: '1rem'}}/>{' Negocio'}</h1>
          <br/>
          <h2 className='mb-2'>{'Tenemos todo lo que necesitas'}</h2>
          <br/>
          <Button size='lg' as={HashLink} to='#form_fintech' className='primary-btn btn-large inline fint-btn' >
              Contáctanos
            </Button>
        </Col>
    </Row>
</Fragment>

  )
}

export default SectionImage1
