import React from 'react';
import { Button, Container} from 'react-bootstrap';
import PropTypes from 'prop-types';

const SectionPersonas5 = ({linkApp}) => {
  return (
    <section className='app-green text-center' style={{ padding: '7rem 0'}}>
      <Container>
        <h1 style={{ color: '#FFFFFF' }}>
          No volverás a utilizar nada más
        </h1>
        <h1 style={{ color: '#FFFFFF' }}>
          ¿Estás listo?
        </h1>
        <h2 style={{ marginTop: '2rem' }}>Descarga la app, regístrate y comienza a usarla</h2>
        <a className='btn-a' target='_blank' rel="noreferrer" href={linkApp}>
          <Button style={{ marginTop: '2rem' }} size='lg' className='black-btn btn-large inline'>
            Únete
          </Button>
        </a>
      </Container>
    </section>
  )
}
SectionPersonas5.propTypes = {
  linkApp: PropTypes.string
}
export default SectionPersonas5
