import { lazy } from 'react'

export { default as Card } from './card/Card';
export { default as FinancialInformation }  from './finance/FinancialInformation';
export { default as Documents }  from './finance/Documents';
export { default as FintechsIconText } from './fintech/FintechsIconText';
export { default as SectionFintechs1 } from './fintech/SectionFintechs1';
export { default as SectionFintechs2 } from './fintech/SectionFintechs2';
export { default as SectionFintechs3 } from './fintech/SectionFintechs3';
export { default as SectionFintechs4 } from './fintech/SectionFintechs4';
export { default as SectionFintechs5 } from './fintech/SectionFintechs5';
export { default as Form } from './form/Form';
export { default as Input } from './form/Input';
export { default as MaskInput } from './form/MaskInput';
export { default as Select } from './form/Select';
export { default as TextArea } from './form/TextArea';
export { default as HeaderTitle } from './header/HeaderTitle';
export { default as DigitalLife } from './people/DigitalLife';
// export { default as PersonsIconText } from './people/PersonsIconText';
export const PersonsIconText = lazy(() => import('./people/PersonsIconText'));
// export { default as SectionPersons2 } from './people/SectionPersons2';
export const SectionPersons2 = lazy(() => import('./people/SectionPersons2'));
// export { default as SectionPersons3 } from './people/SectionPersons3';
export const SectionPersons3 = lazy(() => import('./people/SectionPersons3'));
// export { default as SectionPersons4 } from './people/SectionPersons4';
export const SectionPersons4 = lazy(() => import('./people/SectionPersons4'));
export { default as SectionPersons5 } from './people/SectionPersons5';
export { default as BannerQandA } from './q&a/Banner';
export { default as SectionQuestion } from './q&a/SectionQuestion';
export { default as Footer } from './Footer';
export { default as IconText } from './IconText';
export { default as Layout } from './Layout';
export { default as Navbar } from './Navbar';
export { default as NotFoundLayout } from './NotFoundLayout';