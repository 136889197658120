import { Card, BannerQandA, SectionQuestion } from 'components';
import React, { useEffect } from 'react';
import { Card as BCard, Container, Row } from 'react-bootstrap';

//Stylesheet
import 'styles/Q&A.scss';
import { FAQ } from 'temp/temp';

const QandA = () => {
    useEffect(() => {
        document.body.className='persons';
    }, [])

    return (
        <main id='faqs' style={{ width: '100vw', paddingBottom: 40 }}>
            <Container id='contact' style={{ height: '90%', paddingTop: '5.5rem' }}>
                <BannerQandA/>
                <Card>
                    <BCard.Body  style={{ padding: '4rem', boxShadow: '0px 0px 25px rgba(201, 210, 226, 0.5)' }}>
                        {FAQ.map((i) => (
                            <Row className='justify-content-center' key={i.title}>
                                <SectionQuestion data={i} />
                            </Row>
                        ))}
                    </BCard.Body>
                </Card>
            </Container>
        </main>
     );
}
 
export default QandA;