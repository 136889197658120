import PropTypes from 'prop-types';

const HeaderTitle = ({ className, title, subtitle }) => {
    return (
        <div className={`header-title ${className || ''}`}>
            <div className='title-head'>
                {title}
            </div>
            <div className='header-title-line' />
            <div className='header-title-sub'>
                {subtitle}
            </div>
        </div>
    )
}
HeaderTitle.propTypes = {
    className: PropTypes.string,
    title: PropTypes.any,
    subtitle: PropTypes.any
}
export default HeaderTitle;