import emailjs from '@emailjs/browser';
import { Card, Input, MaskInput, TextArea } from 'components';
import { Button, Card as BCard, Col, Row } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import useValidation from 'hooks/useValidation';

const ContactForm = () => {
    const form = useRef();

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const { emailValidation, wordValidation } = useValidation();

    const handleSubmit = async (e) => {
        try {
          e.preventDefault();
          const response = await emailjs.sendForm(process.env.REACT_APP_EMAIL_SERVICE_ID, process.env.REACT_APP_EMAIL_CONTACT_TEMPLATE, form.current, process.env.REACT_APP_EMAIL_KEY);
          if (response.text === 'OK') {
            Swal.fire({
              title: 'Proceso Exitoso',
              text: 'Hemos recibido tu información, te contactaremos lo antes posible !',
              icon: 'success'
            });
            setName('');
            setEmail('');
            setPhone('');
            setSubject('');
            setMessage('');
          } else {
            Swal.fire({
              title: 'Proceso Fallido',
              text: 'Tuvimos un problema intentalo más tarde.',
              icon: 'error'
            });
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: `Tuvimos un problema. ${error.message}`,
            icon: 'error'
          });
        }
      }
    
    useEffect(() => {
        if (name && phone && phone.replace(/ /g, '').length === 10 && emailValidation(email) && subject && message) {
            setIsDisabled(false);
        } else  {
            setIsDisabled(true);
        }
    }, [name, email, message, subject, phone])

    return (
        <Card className='mb-4' style={{ border: 'none', boxShadow: '0px 0px 25px rgba(201, 210, 226, 0.5)' }}>
            <BCard.Body style={{ padding: '4rem'}}>
                <form ref={form} onSubmit={isDisabled ? () => {} : handleSubmit}>
                    <Row>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Input label='Nombre Completo' groupClassName='mb-2'
                                id='name'
                                name='name'
                                value={name}
                                onChange={(e) => setName(prev => e.target.value ?  wordValidation(e.target.value.trimStart()) ? e.target.value.trimStart() : prev : '')}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Input label='Correo' groupClassName='mb-2'
                                id='email'
                                name='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value.trim())}
                                textClassName={emailValidation(email) ? '' : 'text-danger'}
                                text={email ? emailValidation(email) ? '' : 'Formato de Correo Invalido' : ''}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <MaskInput
                                groupClassName='mb-2'
                                label='Número de Teléfono'
                                id='phone'
                                name='phone'
                                value={phone}
                                mask={'00 0000 0000'}
                                onAccept={(e) => setPhone(e ? e : '')}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={6} lg={6}>
                            <Input label='Asunto' groupClassName='mb-2'
                                id='subject'
                                name='subject'
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <TextArea label='Mensaje' groupClassName='mb-2' rows={5}
                                id='message'
                                name='message'
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                            />
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12}>
                            <p>
                                Banco PagaTodo sabe del compromiso que tiene con la privacidad de sus clientes y visitantes, por lo que solicita
                                que lea su Política de Privacidad a fin de obtener una explicación clara de cómo recopilamos, utilizamos,
                                divulgamos, transferimos y almacenamos la información. 
                            </p>
                        </Col>
                        <Col className='text-center my-3' xs={12} sm={12} md={12} lg={12}>
                            <Button type='submit' disabled={isDisabled} className='primary-btn btn-large' size='lg'>Enviar</Button>
                        </Col>
                    </Row>
                </form>
            </BCard.Body>
        </Card>
    )
}
export default ContactForm;