import { Card as BCard } from 'react-bootstrap';
import PropTypes from 'prop-types';

const Card = ({ children, ...props }) => {
    return (
        <BCard {...props}>
            {children}
        </BCard>
    )
}
Card.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
}
export default Card;