import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'react-bootstrap';

const SectionQuestion = ({data}) => {

    return ( 
        <div style={{marginBottom: '3rem' }}>
            <h2>{data.title}</h2>
            <Accordion>
            {
                data.content.map((i) => (
                    <Accordion.Item eventKey={i.key} key={i.key}>
                        <Accordion.Header style={{margin: 0, border: 'none'}}>{i.subtitle}</Accordion.Header>
                        {/* <Accordion.Body dangerouslySetInnerHTML={{__html: i.content}} ></Accordion.Body> */}
                        <Accordion.Body>{i.content}</Accordion.Body>
                    </Accordion.Item>
                ))
            }
            </Accordion>
        </div>
     );
}
 
SectionQuestion.propTypes = {
    data: PropTypes.any

}
export default SectionQuestion;