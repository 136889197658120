import { useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const SecurityNotice = () => {
    useEffect(() => {
        document.body.className='persons';
    }, [])

    return (
        <main style={{ width: '100vw', paddingBottom: 40 }}>
            <Container id='security' style={{ height: '90%', paddingTop: '5.5rem' }}>
                <Row className={'mb-5 w-75 m-auto'}>
                    <Col className={'text-center'} xs={12} sm={12} md={12} lg={12}>
                        <h1>Aviso de Seguridad</h1>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-2 mt-2'>{'Medidas de Seguridad sobre su Información Bancaria en Internet'} </h6>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <p>Evita utilizar buscadores o ligas para ingresar a la página de Banco PagaTodo ya que éstos te pueden direccionar a sitios falsos.</p>
                        <p className='mt-4' >Considera la información recibida a través de las redes sociales como Facebook o twitter ya que pueden direccionarte a sitios falsos para robar tu información.   </p>
                        <p className='mt-4' >Accede a la página de Banco PagaTodo capturando tú mismo la dirección: <a href='https://www.bancopagatodo.com' target='_blank' rel='noreferrer'>https://www.bancopagatodo.com</a> en tu navegador y no de direcciones que provengan de un correo electrónico, posteo en redes sociales o ligas adjuntas.</p>
                        <p className='mt-4' >Realiza operaciones financieras y accede a servicios personales (correo electrónico, redes sociales, etc.) desde computadores que consideres seguras y sin sospecha alguna de virus (Ejemplo: tu computadora personal o la de tu trabajo).</p>
                        <p className='mt-4' >No compartas contraseñas, ni permitas que alguien más opere tu  TOKEN Banco PagaTodo.  </p>
                        <p className='mt-4' >{'Es una práctica muy conocida entre los usuarios de computadoras el "Phishing" y trata de convencerte a enviar información a través de link o correo electrónico donde solicita tus datos personales con las frases como “Sincroniza tu token”, “Actualiza tus datos” ó “Tu cuenta ha sido bloqueada”. NO RESPONDA ESTE TIPO DE MENSAJE.'}</p>
                        <p className='mt-4' >Mantén segura tu computadora instalando las últimas actualizaciones de sistema operativo, antivirus, y en general de todas las aplicaciones que utilices.</p>
                        <p className='mt-4' >No descargues programas o archivos de origen desconocido, ya sea desde un sitio web o desde un correo electrónico.</p>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-2 mt-2'>{'Medidas de Seguridad con su Tarjeta'} </h6>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <p >Al recibir tu tarjeta, revisa el empaque, no deberá contener ranuras, señal de violación o aperturas. En caso de identificar cualquiera de estas señales, repórtala de inmediato</p>
                        <p className='mt-4' >Una vez con tu tarjeta, fírmala con bolígrafo de tinta negra o azul.</p>
                        <p className='mt-4' >No pierdas de vista tu tarjeta al utilizarla en un comercio, solicita siempre que se realice el cobro en tu presencia y cuando se te regrese asegúrate de que sea la tuya.</p>
                        <p className='mt-4' >No permitas que otras personas utilicen tu tarjeta en tu nombre.</p>
                        <p className='mt-4' >Una vez que tu tarjeta esté vencida, cancelada ó deteriorada, destrúyela inutilizando la firma y cortándola en fragmentos.</p>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-2 mt-2'>{'Precauciones en el uso de sus Medios de Pago'} </h6>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <p >Cargue solamente identificación y tarjetas que sabes que vas a utilizar, conservándolas en lugar seguro. </p>
                        <p className='mt-4' > En caso de robo o extravío de su tarjeta, repórtela de inmediato a través de nuestra Línea Banco PagaTodo.</p>
                        <p className='mt-4' > La custodia de la tarjeta es tu responsabilidad. No permitas que extraños tengan acceso.</p>
                        <p className='mt-4' > No permitas que terceros tengan acceso a las claves y contraseñas utilizadas en la Plataforma Tecnológica para Dispersiones “REDIS”.</p>
                        <p className='mt-4' > No compartas tu acceso al aplicativo TOKEN Banco PagaTodo con otras personas.</p>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-2 mt-2'>{'Seguridad en los Accesos'} </h6>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <p > Mantén la confidencialidad de las claves y contraseñas de acceso a los diferentes productos o servicios, y nunca las compartas con nadie más, incluyendo cónyuge, hijos, hermanos, asistentes, empleados de confianza, empleados y ejecutivos de servicio telefónico del banco, etc.</p>
                        <p className='mt-4' >El Ejecutivo de Atención Telefónica no está autorizado a pedirte tus claves de acceso a tu cuenta o tarjeta, sólo tú la debes usar y conocer. </p>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-2 mt-2'>{'Recomendaciones Banco PagaTodo'} </h6>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <p> Banco PagaTodo apertura cuentas sin ninguna cuota o comisión, y se tramita en su Oficina Administrativa o por medio de sus Ejecutivos Comerciales. </p>
                        <p className='mt-4' > Nunca permitas ni solicites que un funcionario de Banco PagaTodo, realice actividades que sólo a ti te corresponden como titular de la cuenta, por ejemplo acudir a la Oficina Administrativa para tramitar una cancelación o solicitar una transferencia SPEI, no importa la confianza que le tengas y el tiempo que lleves de conocerlo. </p>
                        <p className='mt-4' > Utiliza nuestro Servicio de Atención Telefónica para reportar cualquier situación que te parezca irregular o sospechosa.        </p>
                        <p className='mt-4 mb-2' > Banco PagaTodo te recomienda que generes contraseñas seguras y para ello te sugerimos armar tu clave tomando en cuenta los siguientes:    </p>
                        <ol>
                            <li>
                                Utiliza números
                            </li>
                            <li>
                                Utiliza letras
                            </li>
                            <li>
                                Utiliza mayúsculas y minúsculas
                            </li>
                            <li>
                                Utiliza caracteres especiales
                            </li>
                        </ol>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <h6 className='mb-2 mt-2'>{'Responsabilidad del Usuario de Servicios Bancarios'} </h6>
                    </Col>
                    <Col className='my-2' xs={12} sm={12} md={12} lg={12}>
                        <p className='mb-2'>Banco PagaTodo no será responsable de los errores del cliente o negligencia al usar los Servicios de Banca Electrónica REDIS; asimismo no cubre pérdidas ocasionadas por:  </p>
                        <ol>
                            <li>
                                Errores de captura o el uso indebido del servicio
                            </li>
                            <li>
                                Compartir la contraseña que ocasione accesos no autorizados a cuentas.
                            </li>
                            <li>
                                Dejar la computadora sin atención durante una sesión en línea.
                            </li>
                        </ol>
                        <p className='mt-4' > <b> El Usuario es responsable de mantener confidencial la contraseña, números de cuenta, información personal de identificación y otros datos sensibles de la cuenta y/o servicios bancarios.</b></p>
                    </Col>
                </Row>
            </Container>
        </main>
    )
}
export default SecurityNotice;
