import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

export const AppContext = createContext();

export const AppProvider = ({children}) => {
    const [pathName, setPathName] = useState('');
    const { pathname } = useLocation();

    useEffect(() => {
        if (pathname)
            setPathName(pathname);
    }, [pathname])

    return (
        <AppContext.Provider value={{ path: pathName }}>
            {children}
        </AppContext.Provider>
    )
}

AppProvider.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]) 
}