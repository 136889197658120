import { Form } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import PropTypes from 'prop-types';

const MaskInput = ({ label, text, labelClassName, groupClassName, controlId, textClassName, mask, size, className, ...props }) => {
    return (
        <Form.Group className={groupClassName} controlId={controlId}>
            <Form.Label className={labelClassName}>{label}</Form.Label>
            <IMaskInput
                mask={mask}
                className={`form-control ${className} ${size ? size === 'lg' ? 'form-control-lg' : 'form-control-sm' : ''}`}
                {...props}
            />
            <Form.Text className={textClassName}>{text}</Form.Text>
        </Form.Group>
    )
}
MaskInput.propTypes = {
    className: PropTypes.string,
    label: PropTypes.any,
    text: PropTypes.any,
    labelClassName: PropTypes.string,
    groupClassName: PropTypes.string,
    controlId: PropTypes.string,
    textClassName: PropTypes.string,
    mask: PropTypes.string.isRequired,
    size: PropTypes.oneOf(['sm', 'lg'])
}
export default MaskInput