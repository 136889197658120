import { Col, Container, Row } from "react-bootstrap";
// images
import about from 'images/about/about.png';
import persons from 'images/about/about-persons.png';
import solutionOne from 'images/about/about-solution-1.png';
import solutionTwo from 'images/about/about-solution-2.png';
import solutionThree from 'images/about/about-solution-3.png';
//Stylesheet
import'styles/about-contact.scss';
import { useEffect } from "react";

const data = [solutionOne, solutionTwo, solutionThree];

const AboutUs = () => {
    useEffect(() => {
        document.body.className='persons';
    }, [])
    return (
        <main style={{ width: '100vw', paddingBottom: 40 }}>
            <Container id='us' style={{ height: '90%', paddingTop: '5.5rem' }}>
                <Row className={'mb-5'}>
                    <Col className={'text-center'} xs={12} sm={12} md={12} lg={12}>
                        <h1>Un Banco 100% Digital</h1>
                        <h4>Se parte de la nueva manera de usar el dinero</h4>
                        <img className='mb-3' style={{ height: '25rem' }} src={about} alt='about' />
                        <h2 className='mb-3'>¿Quienes somos?</h2>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <p>Somos un banco digital que busca evolucionar la manera en la que gestionas tu dinero o el de tu negocio acercándote a un mundo en donde todas tus transacciones son fáciles y seguras.</p>
                    </Col>
                    <Col xs={12} sm={12} md={6} lg={6}>
                        <p>
                        Imagina poder abrir una cuenta bancaria desde la app en minutos, pagar utilizando CoDi® o comprar en línea de forma segura con un CVV dinámico. Con Banco PagaTodo eso es posible y de una manera simple, fácil y sin comisiones ocultas. Dile adiós a lo tradicional y hola a Banco PagaTodo.
                        </p>
                    </Col>
                </Row>
            </Container>
            <div className='persons-img-div' />
            <img className='persons-img'  src={persons} alt='persons' />
            <Container className='content'>
                <p className='text-center mb-5'>
                    En nuestro departamento de desarrollo e innovación creamos herramientas tecnológicas hechas con la finalidad de ayudarte a controlar eficientemente tu dinero. Desde una app en donde controlas todas tus finanzas en un solo lugar hasta un catálogo de APIs, listas para que crees soluciones personalizadas para tu negocio y clientes.
                </p>
                <h1 className='text-center'>Soluciones de Negocio</h1>
                <Row className='mt-3 mb-5'>
                    {data.map((item, index) => (
                        <Col key={index} xs={12} sm={12} md={4} lg={4} xl={4}>
                            <img style={{ margin: 'auto', width: '100%', alignItems: 'baseline' }} src={item} alt={`solution-${index+1}`} />
                        </Col>
                    ))}
                </Row>
            </Container>
        </main>
    )
}
export default AboutUs;